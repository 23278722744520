import React from "react";
import notFoundPhoto from "../../../assets/images/Group 279.png";

const ConfEmptyPage = (props) => {

  return (
    <div className="conf-empty-page">
      <div style={{ display: "flex", flexDirection: "column", width: "300px" }}>
        <img src={notFoundPhoto} alt="" />
        <p>{props.text}</p>
      </div>
    </div>
  )
}

export default ConfEmptyPage
