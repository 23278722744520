import React from "react";
import FloorPlan from "../../../assets/images/floor-plan.png";
import {openFilesPopup} from "../../../store/actions/products";
import {useDispatch, useSelector} from "react-redux";
import DragAndDrop from "../dragAndDrop";

const Runner = (props) => {
  const dispatch = useDispatch()
  const readyForSubmit = useSelector(state => state.products.readyForSubmit.runner)
  const isReadyForSubmit = readyForSubmit.find(item => item.product_schema_id === props.runner[0].public_id)
  const styles = {}
  if (isReadyForSubmit) {
    styles.backgroundImage = `url(${URL.createObjectURL(isReadyForSubmit.product_photo)})`
  }

  return (
    <>
      <div className="map-zone-runner">
        <DragAndDrop
          class={`map-zone-r-top ${props.runner[0].is_reserved ? 'reserved' : ''}`}
          onClick={() => dispatch(openFilesPopup(true, props.runner[0].index))}
          index={1}
        >
          <span style={styles}>{props.runner[0].is_reserved ? 'Reserved' : 'Roll Banner'}</span>
        </DragAndDrop>
        <DragAndDrop
          class={`map-zone-r-right ${props.runner[0].is_reserved ? 'reserved' : ''}`}
          onClick={() => dispatch(openFilesPopup(true, props.runner[0].index))}
          index={1}
        >
          <span style={styles}>{props.runner[0].is_reserved ? 'Reserved' : 'Roll Banner'}</span>
        </DragAndDrop>
        <DragAndDrop
          class={`map-zone-r-bottom ${props.runner[0].is_reserved ? 'reserved' : ''}`}
          onClick={() => dispatch(openFilesPopup(true, props.runner[0].index))}
          index={1}
        >
          <span style={styles}>{props.runner[0].is_reserved ? 'Reserved' : 'Roll Banner'}</span>
        </DragAndDrop>
        <DragAndDrop
          class={`map-zone-r-left ${props.runner[0].is_reserved ? 'reserved' : ''}`}
          onClick={() => dispatch(openFilesPopup(true, props.runner[0].index))}
          index={1}
        >
          <span style={styles}>{props.runner[0].is_reserved ? 'Reserved' : 'Roll Banner'}</span>
        </DragAndDrop>
      </div>
      <img src={FloorPlan} alt=""/>
    </>
  )
}

export default Runner
