import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import store from '../config/redux-store';

import { setParam } from '../store/actions/ui';

const { dispatch } = store;

class Loader {
  constructor() {
    throw new Error('Class does not support object creation!');
  }

  
  static loaders = {};
  
  static groups = {};
  
  static nextId = 1;
  
  static show(id) {
    if (isEmpty(this.loaders)) {
      // show loader
      dispatch(setParam('pageLoader', true));
    }
    
    const key = id || this.nextId++;

    this.loaders[key] = true;

    return key;
  }

  static hide(id = null) {
    return new Promise((resolve, reject) => {
      if (isNumber(id) || !!id) {
        delete this.loaders[id];
      } else {
        this.loaders = {};
      }

      if (isEmpty(this.loaders)) {
        // hide loader
        dispatch(setParam('pageLoader', false));
        resolve();
      }
    });
  }

  static getGroup(groupName = null) {
    return this.groups[groupName];
  }

  static addToGroup(id = null, groupName = null) {
    if (!isNumber(id) && !id) {
      return console.error('LOADER.addToGroup: Please provide `id`.');
    }
    if (!groupName) {
      return console.error('LOADER.addToGroup: Please provide `groupName`.');
    }

    if (!this.groups[groupName]) {
      this.groups[groupName] = [];
    }

    return this.groups[groupName].push(id);
  }

  static hideGroup(groupName = null) {
    if (!groupName) {
      console.error('LOADER.hideGroup: Please provide `groupName`.');
      return;
    }

    const group = this.groups[groupName];

    if (group) {
      group.map(id => this.hide(id));
      delete this.groups[groupName];
    } else {
      this.hide(groupName);
    }
  }
}

export default Loader;
