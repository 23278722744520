import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import LoaderService from "../services/loader-service";
import ApiService from "../services/api-service";
import { verifyToken } from "../store/actions/auth";
import Error from "./error";
import ContinueCheckout from "./continue-checkout";
import { Route, Router } from "react-router";
import MainRoute from "./MainRouter";
import { AuthContextProvider } from "../context/AuthContext";
import { GlobalConfigDataContextProvider } from "../context/GlobalConfigDataContext";

class AppRoutes extends Component {
  constructor(props) {
    super(props);

    this.showPageLoader();

    const { language = "en" } = props.auth.data || {};
    const lang = language.toLowerCase();

    ApiService.getTranslations("en").then(payload => {
      props.addTranslationForLanguage(payload, "en");
    });

    ApiService.getTranslations(lang).then(payload => {
      props.addTranslationForLanguage(payload, lang);
      props.setActiveLanguage(lang);
    });

    props.history.listen(() => {
      this.showPageLoader();
    });
  }

  showPageLoader = () => {
    LoaderService.hideGroup("pageLoader");
    LoaderService.addToGroup(LoaderService.show("routeChange"), "pageLoader");
  };

  render() {
    const { history } = this.props;
    return (
      <AuthContextProvider>
        <GlobalConfigDataContextProvider>
          <Router history={history}>
            <MainRoute />
          </Router>
        </GlobalConfigDataContextProvider>
      </AuthContextProvider>
    );
  }
}

AppRoutes.propTypes = {
  history: PropTypes.shape({}).isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyToken: () => dispatch(verifyToken())
  };
}

const enhance = compose(
  withLocalize,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(AppRoutes);
