import React, {useEffect, useRef, useState} from "react";
import arrow from "../../../assets/images/arrow-back.svg"
import plus from "../../../assets/images/plus-circle.svg"
import checkmarkIcon from "../../../assets/images/checkmark-green.svg"
import errorIcon from "../../../assets/images/error-icon.svg"
import {ValidationHelper} from "../../../helpers/ValidationHelper";
import {CroppieModal} from "../../../components/modal";
import Spinner from "../../../components/loader";

const StandUpload = (props) => {

  const [filesNames, setFileNames] = useState({
    bannerFile: '',
    pdfPresentation: '',
    companyVideo: '',
  })

  const [state, setState] = useState({
    submitting: false,
    formData: {
      phoneNumber: '',
      hooryWebsite: '',
      website: '',
      companyName: '',
      description: '',
      bannerFile: '',
      pdfPresentation: '',
      companyVideo: '',
    },
    formDaraErrors: {
      phoneNumber: false,
      website: false,
      hooryWebsite: false,
      companyName: false,
      description: false,
      bannerFile: false,
      pdfPresentation: false,
      companyVideo: false,
    }
  })

  const [isImageCropped, setIsImageCropped] = useState(false)
  const [validationStatus, setValidationStatus] = useState(false)
  const formFields = useRef(null)

  const formValidation = (submit) => {
    const errors = {...state.formDaraErrors}
    errors.bannerFile = !state.formData.bannerFile
    errors.pdfPresentation = !state.formData.pdfPresentation
    errors.companyVideo = !state.formData.companyVideo
    errors.phoneNumber = !state.formData.phoneNumber
    errors.website = !state.formData.website || !ValidationHelper.WebSiteValidation(state.formData.website)
    errors.hooryWebsite = !state.formData.hooryWebsite ? false : !ValidationHelper.WebSiteValidation(state.formData.hooryWebsite)
    errors.description = !state.formData.description
    errors.companyName = !state.formData.companyName
    errors.bannerFile = !state.formData.bannerFile

    if (errors.bannerFile ||
      errors.pdfPresentation ||
      errors.companyVideo ||
      errors.phoneNumber ||
      errors.website ||
      errors.hooryWebsite ||
      errors.description ||
      errors.companyName ||
      errors.bannerFile) {
      if (submit) {
        formFields.current.scrollTo(0,formFields.current.scrollHeight)
        setState({
          ...state,
          formDaraErrors: errors
        })
        return false
      } else {
        setValidationStatus(false)
      }
    } else {
      if (submit) {
        setState({
          ...state,
          submitting: true,
          formDaraErrors: errors
        })
        return true
      } else {
        setValidationStatus(true)
      }
    }
  }

  const handleDataUpdate = (e, name) => {

    if (name === "phoneNumber" && !!e && e !== '+' && e !== '+(' && !ValidationHelper.MobilePhoneValidation(e)) {
      return
    }

    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: e
      }
    })
  }

  const saveImage = async (image) => {
    setIsImageCropped(true)
    if (ValidationHelper.ImageValidation(image)) {
      try {
        setState({
          ...state,
          formData: {
            ...state.formData,
            bannerFile: image
          },
          formDaraErrors: {
            ...state.formDaraErrors,
            bannerFile: false
          }
        })
      } catch (e) {

      }
    } else {
      setState({
        ...state,
        formData: {
          ...state.formData,
          bannerFile: null
        },
        formDaraErrors: {
          ...state.formDaraErrors,
          bannerFile: true
        }
      })
    }
  }

  const handleChangeFile = async (e, name) => {
    const file = e.target.files[0]
    if (!file) return
    let validate = false
    let filePath = ''
    setFileNames({
      ...filesNames,
      [name]: ''
    })

    setState({
      ...state,
      formData: {
        ...state.formData,
        [name]: ''
      },
      formDaraErrors: {
        ...state.formDaraErrors,
        [name]: false
      }
    })
    if (file.type.indexOf("image") > -1) {
      setIsImageCropped(false)
      validate = true
      e.target.value = null
      filePath = file
    } else if (file.type.indexOf("pdf") > -1) {
      if (ValidationHelper.PDFValidation(file)) {
        filePath = file
        validate = true
      }
    } else if (file.type.indexOf("video") > -1) {
      if (ValidationHelper.VideoValidation(file)) {
        filePath = file
        validate = true
      }
    }
    if (validate) {
      setFileNames({
        ...filesNames,
        [name]: file.name
      })
      setState({
        ...state,
        formData: {
          ...state.formData,
          [name]: filePath
        },
        formDaraErrors: {
          ...state.formDaraErrors,
          [name]: false
        }
      })
    } else {
      setState({
        ...state,
        formData: {
          ...state.formData,
          [name]: null
        },
        formDaraErrors: {
          ...state.formDaraErrors,
          [name]: true
        }
      })
    }
  }

  const handleSave = async () => {
    const data = new FormData();

    data.append('product_schema_id', props.formData.locationId)
    data.append('parent_id', props.expoId)
    data.append('parent_type', 1)
    data.append('design_id', props.formData.designId)
    data.append('purchase_id',props.purchaseId)
    data.append('company_name',state.formData.companyName)
    data.append('phone_number',state.formData.phoneNumber)
    data.append('website',state.formData.website)
    if (state.formData.hooryWebsite) {
      data.append('hoory_website', state.formData.hooryWebsite)
    }
    data.append('description', state.formData.description)
    data.append('product_photo', state.formData.bannerFile)
    data.append('product_video', state.formData.companyVideo)
    data.append('company_pdf', state.formData.pdfPresentation)
    data.append('company_video', state.formData.companyVideo)

    if (!formValidation(true)) {
      return
    }

    props.onSave(data).then(payload => {
      if (!payload.__HANDLED_ERROR) {
        window.location.href = 'https://www.pandamr.com/download'
        // window.location.href = window.location.origin + window.location.pathname + `?expo_id=${props.expoId}`;
      } else {
        const errors = {
          phoneNumber: false,
          website: false,
          hooryWebsite: false,
          companyName: false,
          description: false,
          bannerFile: false,
          pdfPresentation: false,
          companyVideo: false,
        }
        payload.data.errors.fields.map(field => {
          switch (field.error_field) {
            case 'company_name':
              errors.companyName = true
              break
            case 'phone_number':
              errors.phoneNumber = true
              break
            case 'website':
              errors.website = true
              break
            case 'description':
              formFields.current.scrollTo(0,formFields.current.scrollHeight)
              errors.description = true
              break
            case 'hoory_website':
              errors.hooryWebsite = true
              break
            case 'photos':
              errors.photos = true
              break
            case 'pdf_presentation':
              errors.pdf_presentation = true
              break
            case 'company_video':
              errors.company_video = true
              break
            case 'videos':
              errors.videos = true
              break
          }
        })
        setState({
          ...state,
          formDaraErrors: errors
        })
      }
    });
  };

  useEffect(() => {
    formValidation(false)
  }, [state.formData])

  return (
    <>
      <div className={"prev-button"} onClick={() => props.changeStep(1)}>
        <img src={arrow} alt=""/>
      </div>
      <div className={"submit-form__form"}>
        <div className={"form__header"}>
          <h2>
            Upload Materials
          </h2>
          <p>
            After identifying a clear marketing formula and materials for your event, the integral part o your outreach
            would be the choice of your booth design.
          </p>
        </div>
        <div className={"form__fields"} ref={formFields}>
          <div className={"fields__files"}>
            <div className="fields__files_image fields__files_file">
              <label className={`file-section`}>
                {state.formData.bannerFile ?
                  <>
                    <img src={checkmarkIcon} alt=""/>
                    <p>{filesNames.bannerFile}</p>
                  </>
                  : state.formDaraErrors.bannerFile ?
                    <>
                      <img src={errorIcon} alt=""/>
                      <p className={'redP'}>.jpg, .jpeg, .png 50mb</p>
                    </>
                    :
                      <>
                        <img src={plus} alt=""/>
                        <p>.jpg, .jpeg, .png 50mb</p>
                      </>
                }
                <input
                  type="file"
                  accept={".jpg, .jpeg, .png"}
                  onChange={e => handleChangeFile(e, 'bannerFile')}
                  multiple={false}
                />
              </label>
              <h4>Logo*</h4>
              <p>Upload your company logo here with black background (exact size 940x270)</p>
            </div>
            <div className="fields__files_pdf fields__files_file">
              <label className={`file-section`}>
                {state.formData.pdfPresentation ?
                  <>
                    <img src={checkmarkIcon} alt=""/>
                    <p>{filesNames.pdfPresentation}</p>
                  </>
                  : state.formDaraErrors.pdfPresentation ?
                    <>
                      <img src={errorIcon} alt=""/>
                      <p className={'redP'}>.pdf 100mb</p>
                    </>
                    :
                      <>
                        <img src={plus} alt=""/>
                        <p>.pdf 100mb</p>
                      </>
                }
                <input
                  type="file"
                  accept={".pdf"}
                  onChange={e => handleChangeFile(e, 'pdfPresentation')}
                />
              </label>
              <h4>Presentation PDF*</h4>
              <p>Upload your presentation in pdf format</p>
            </div>
            <div className="fields__files_video fields__files_file">
              <label className={`file-section`}>
                {state.formData.companyVideo ?
                  <>
                    <img src={checkmarkIcon} alt=""/>
                    <p>{filesNames.companyVideo}</p>
                  </>
                  : state.formDaraErrors.companyVideo ?
                    <>
                      <img src={errorIcon} alt=""/>
                      <p className={'redP'}>.mp4, .mkv, .avi, .mov, .flv 100mb</p>
                    </>
                    :
                      <>
                        <img src={plus} alt=""/>
                        <p>.mp4, .mkv, .avi, .mov, .flv 100mb</p>
                      </>
                }
                <input
                  type="file"
                  accept={".mp4, .mkv, .avi, .mov, .flv"}
                  onChange={e => handleChangeFile(e, 'companyVideo')}
                />
              </label>
              <h4>Presentation MP4*</h4>
              <p>Upload your presentation in mp4 format in 480p (720x480) resolution</p>
            </div>
          </div>
          <div className={"fields__company-info"}>
            <label className={`fields__company-info_field ${state.formDaraErrors.companyName ? 'error' : ''}`}>
              <div>Company Name*</div>
              <input
                placeholder="Enter Company Name"
                type="text"
                onChange={e => handleDataUpdate(e.target.value, 'companyName')}
              />
            </label>
            <label className={`fields__company-info_field ${state.formDaraErrors.website ? 'error' : ''}`}>
              <div>Your Website*</div>
              <input
                placeholder="www.example.com"
                type="url"
                onChange={e => handleDataUpdate(e.target.value, 'website')}
              />
            </label>
            <label className={`fields__company-info_field ${state.formDaraErrors.phoneNumber ? 'error' : ''}`}>
              <div>Contact Number*</div>
              <input
                type={"tel"}
                placeholder={"+37477777777"}
                value={state.formData.phoneNumber}
                onChange={e => handleDataUpdate(e.target.value, 'phoneNumber')}
              />
            </label>
            <label className={`fields__company-info_field ${state.formDaraErrors.hooryWebsite ? 'error' : ''}`}>
              <div>Hoory</div>
              <input
                placeholder="Enter Hoory Website"
                type="url"
                onChange={e => handleDataUpdate(e.target.value, 'hooryWebsite')}
              />
            </label>
            <label className={`fields__company-info_field textarea ${state.formDaraErrors.description ? 'error' : ''}`}>
              <div>About your Company*</div>
              <textarea
                value={props.formData.description}
                onChange={e => handleDataUpdate(e.target.value, 'description')}
                placeholder={"Company short description..."}/>
            </label>
          </div>
        </div>
        <div className={`next-button ${validationStatus ? 'active' : ''}`}>
          <button onClick={handleSave}>
            SAVE
          </button>
        </div>
        {!isImageCropped && state.formData.bannerFile ?
          <CroppieModal
            data={{file: state.formData.bannerFile, width: 940, height: 270}}
            closeModal={() => setState({...state, formData: {...state.formData, bannerFile: null}})}
            onSave={(file) => saveImage(file)}
            isOpen={true}
          /> : ''
        }
      </div>
      {state.submitting ? <Spinner/> : ''}
    </>
  )
}

export default StandUpload
