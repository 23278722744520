import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';

export const translateManually = ({ text, id, data, options }, fn) => {
  const key = (id || text)
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\./g, '')
    .replace(/[ \t]/g, '_');

  return fn(key, data, options) || text;
};

const CustomTranslate = props => {
  const { translate, id, data, options, children } = props;

  const key = (id || children)
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\./g, '')
    .replace(/[ \t]/g, '_');

  return typeof children === 'function' ? (
    <Translate id={id} data={data} options={options}>
      {children}
    </Translate>
  ) : (
    translate(key, data, options) || children
  );
};

export default withLocalize(CustomTranslate);
