import React, { createContext, useContext, useReducer } from 'react';
import { GlobalConfigData } from "../reducers/GlobaConfigDataContextReducer";

const initialState = {
  expoId: 0,
  conference: 0
};

const initialGlobalConfigDataContext = {
  globalConfigDataState: initialState,
  setGlobalConfigDataState: () => {
  },
};

const GlobalConfigDataContext = createContext(initialGlobalConfigDataContext);

export function GlobalConfigDataContextProvider({ children }) {
  const [globalConfigDataState, setGlobalConfigDataState] = useReducer(GlobalConfigData, initialState);

  return <GlobalConfigDataContext.Provider
    value={{ globalConfigDataState, setGlobalConfigDataState }}>{children}</GlobalConfigDataContext.Provider>;
}

export const UseGlobalConfigDataState = () => useContext(GlobalConfigDataContext);
