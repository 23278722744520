import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import LoaderService from '../../services/loader-service';
import {getPurchase} from '../../store/actions/purchase';
import ErrorBoundary from '../../components/error-boundary';
import AdvertisingContainer from '../../containers/advertising';
import StandContainer from '../../containers/stand';
import Translate from '../../components/translate';
import Spinner from '../../components/loader';
import {MessageModal} from '../../components/modal';
import StandView from '../../containers/stand/standView'
import {getRoom} from '../../store/actions/room';
import Conferances from '../../containers/conferance/Conferances'
import {getConferance} from '../../store/actions/conf';
import {getCSRF} from '../../store/actions/csrf';
import {isCSRF} from '../../helpers/Utilities';
import {Redirect} from "react-router-dom";

class ContinueCheckoutRoute extends Component {
  state = {
    isOpenMessageModal: false,
    messageModalContent: 'server_error',
  };

  componentDidMount() {
    if (this.props.isLoaded) {
      LoaderService.hideGroup('pageLoader');
    }
    if(this.props.auth.data.conference === 1) {
      this.props.getConferances(this.props.auth.data.expo_id)
      if(this.props.auth.data.token && !isCSRF()) {
        this.props.getCsrf()
      }
    } else {
      if(this.props.auth.data.token) {
        this.props.getPurchase(this.props.auth.data.expo_id).then(payload => {
          if (payload.data === null) {
            setTimeout(() => {
              return <Redirect to={'/404'}/>
            }, 2500);
          }
        });
      } else {
        this.props.getRoom(this.props.auth.data.expo_id, { extra_info: true });
      }
    }

  }

  componentDidUpdate() {
    const { isLoaded } = this.props;

    if (isLoaded) {
      LoaderService.hideGroup('pageLoader');
    }
  }

  render() {
    const {
      purchase: { data },
      isLoaded,
    } = this.props;

    return (
      <ErrorBoundary>
        {isLoaded ? (
          (data || this.props.room.data || this.props.getConf.data) ? (
            this.getContainer()
          ) : (
            this.props.purchase.data !== null ?
            <MessageModal
              data={{
                message: (
                  <Translate>Your changes successfully saved, now you don't have any pending purchases.</Translate>
                ),
              }}
              isOpen={true}
            /> :
            <Spinner />
          )
        ) : (
          <Spinner />
        )}
      </ErrorBoundary>
    );
  }

  getContainer = () => {
    const {purchase: {data}} = this.props;
    if(this.props.auth.data.conference === 1) {
      return (
        <Conferances />
      )
    } else {
      if(data) {
        return data[0].product === 0 ?
          <StandContainer
            data={{
              purchaseId: data[data.length - 1].public_id,
              roomId: data[data.length - 1].room,
            }}
          />
          :
          <AdvertisingContainer
            data={{
              purchaseId: data[data.length - 1].public_id,
              roomId: data[data.length - 1].room,
            }}
          />
      } else {
        return (
          <StandView room={this.props.room}/>
        )
      }
    }
  }

}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    purchase: state.purchase.current,
    getConf: state.conf.getConf,
    room: state.room.current,
    isLoaded: (state.auth.data && state.auth.loaded && state.purchase.current.loaded || state.room.current.loaded) || state.conf.getConf.loaded,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRoom: (id, query) => dispatch(getRoom(id, query)),
    getPurchase: id => dispatch(getPurchase(id)),
    getConferances: id => dispatch(getConferance(id)),
    getCsrf: () => dispatch(getCSRF())
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(ContinueCheckoutRoute);
