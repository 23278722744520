export const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export const remove_item_array = (array, item) => {
    let arrayCopy = [...array]
    let index = arrayCopy.indexOf(item)
    if (index !== -1) {
        arrayCopy.splice(index, 1);
        return arrayCopy
    }
}

export const isCSRF = () => localStorage.getItem('CSRF') != undefined ? true : false