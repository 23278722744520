import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import Translate from '../../translate'
import ReactTooltip from "react-tooltip";

const SmallStand = props => {

    const [classStyle, setClassStyle] = useState('')
    const [text, setText]  = useState('')

    useEffect(() => {
        if (props.colorIndex === 0 && classStyle !== 'red-color') {
            setClassStyle('red-color')
            setText('R-')
        } else if (props.colorIndex === 1 && classStyle !== 'blue-color') {
            setClassStyle('blue-color')
            setText('B-')
        } else if (props.colorIndex === 2 && classStyle !== 'green-color') {
            setClassStyle('green-color')
            setText('G-')
        } else if (props.colorIndex === 3 && classStyle !== 'yellow-color') {
            setClassStyle('yellow-color')
            setText('O-')
        }
    }, [props.colorIndex])

    return (
      <div data-tip={props.item.is_reserved ? props.item.reserved_by.office : ''}
           data-class={'map-col--tooltip'}
           data-arrow-color={'transparent'}
           data-place={'right'}
           className={classNames(`map-col map-col-small ${classStyle}`, {
             'innerSmall': props.isReverse,
             'map-col--reserved': props.item.is_reserved,
             'map-col--disabled': props.purchasedLabel !== props.item.label,
             'map-col--selected': props.item.public_id === props.selectedId,
           })}
           onClick={() => props.handleSelect(props.item)}>
        <p>
          {props.item.is_reserved && props.item.reserved_by.office || (
            <>
              {text === '' ?
                <>
                  <Translate>{props.item.index}</Translate>
                  <br/>
                  <Translate>(Small)</Translate>
                </>
                :
                <Translate>{`${text}${props.item.index}`}</Translate>
              }
            </>
          )}
        </p>
        {props.item.is_reserved ? <ReactTooltip/> : ''}
      </div>
    )
}

export default SmallStand
