import React from "react";
import "./../src/assets/font-icons/style.css";
import "./assets/sass/main.scss";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import ErrorBoundary from "./components/error-boundary";
import Root from "./routes";
import store, { history } from "./config/redux-store";
import * as serviceWorker from "./serviceWorker";

String.prototype.removeCharsAt = function(index, length) {
  let tmp = this.split("");
  tmp.splice(index - 1, length);
  return tmp.join("");
};

// test 

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Root history={history} store={store} />
    </AppContainer>,
    document.getElementById("app-root")
  );
};

render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept("./routes", () => render());
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
