import React from "react";
import FloorPlan from "../../../assets/images/floor-plan.png";
import {openFilesPopup} from "../../../store/actions/products";
import {useDispatch} from "react-redux";
import DragAndDrop from "../dragAndDrop";

const Monitor = (props) => {

  const monitors = props.monitors

  const dispatch = useDispatch()

  const handleMonitor = (item) => {

    return (
      <DragAndDrop
        class={item.is_reserved ? 'reserved' : ''}
        onClick={() => dispatch(openFilesPopup(true, item.index))}
        index={item.index}
      />
    )
  }

  return (
    <>
      <div className="map-zone-monitor">
        {handleMonitor(monitors[0])}
        {handleMonitor(monitors[1])}
      </div>
      <img src={FloorPlan} alt=""/>
      <div className="map-zone-monitor rotated">
        {handleMonitor(monitors[2])}
        {handleMonitor(monitors[3])}
      </div>
    </>
  )
}

export default Monitor
