import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from './spinner';

class BigLoader extends Component {
  constructor(props) {
    super(props);

    this.state = { showed: props.show };
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.show !== nextProps.show) {
      if (nextProps.show) {
        clearTimeout(this.timeout);
        this.setState({
          showed: true,
        });
      } else {
        this.timeout = setTimeout(() => this.setState({ showed: false }), 300);
      }
    }
  }

  render() {
    const { showed } = this.state;
    return (
      <div className={`page-loader ${!showed ? 'loaded' : ''}`}>
        <div className="loader-section section-left" />
        <div className="loader-section section-right" />
        <Loader />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    show: state.ui.pageLoader,
  };
}

export default connect(mapStateToProps)(BigLoader);
