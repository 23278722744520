import createAction from './create-action';
import ApiService from '../../services/api-service';

/**
 * ACTION TYPES
 */

export const VERIFY_TOKEN = 'VERIFY_TOKEN';

/**
 * THUNKS
 */

export const verifyToken = data => dispatch => {
  return dispatch(
    createAction(VERIFY_TOKEN, ApiService.getUser('_me'), {
      __PARSABLE_ERROR: true,
    })
  );
};
