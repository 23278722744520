// import createAction from './create-action'
// import ApiService from '../../services/api-service';
import axios from 'axios'
export const GET_CSRF = 'GET_CSRF';

export const getCSRF = () => async (dispatch) => {
    try {
        const res = await axios.get(`https://expo-content-dev.pandamr.com/api/generate-csrf/`);
        console.log(res.data, 'csrf')
        localStorage.setItem('CSRF', res.data.data)
        // dispatch({
        //     type: 'GETCSRF',
        //     payload: res.data,
        // });
    } catch (err) {
        dispatch({
            type: 'GETCSRF_ERROR',
            payload: { msg: err.response.statusText, status: err.response.status },
        });
    }
};
