import * as actionTypes from '../actions/purchase';

const initialState = {
  current: {
    loaded: true,
    error: null,
    data: null,
  },
  list: {
    loaded: true,
    error: null,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${actionTypes.GET_PURCHASE}_LOADING`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_PURCHASE}_SUCCESS`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }

    case `${actionTypes.GET_PURCHASE}_ERROR`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: true,
          error: action.payload,
        },
      };
    }
    ///////

    case `${actionTypes.GET_PURCHASE_LIST}_LOADING`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_PURCHASE_LIST}_SUCCESS`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }
    case `${actionTypes.GET_PURCHASE_LIST}_ERROR`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: true,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
