import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addProductForSubmit, setImageToCroppie, updateProduct} from "../../store/actions/products";

const DragAndDrop = (props) => {

  const dropRef = useRef(null)
  const dispatch = useDispatch()
  const reservingProducts = useSelector(state => state.products)
  const halls = useSelector(state => state.room.current.data.halls)
  const roomId = useSelector(state => state.room.current.data.room_id)

  const [dragAndDrop, setDragAndDrop] = useState({
    dragging: false,
    activeForUse: true
  })

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
    }
  }

  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.target.classList.contains('disabled')) return;

    let media
    if (e.dataTransfer.files.length) {
      media = e.dataTransfer.files[0]
    } else {
      media = reservingProducts[reservingProducts.selectedProductName === 'monitor' ? 'videos' : "images"][reservingProducts.draggingFileId]
    }

    if (reservingProducts.selectedProductName === 'monitor') {

      const product = halls[reservingProducts.hallIndex].zones[reservingProducts.zoneIndex][reservingProducts.selectedProductName][props.index - 1]
      const isRes = reservingProducts.readyForSubmit[reservingProducts.selectedProductName].find(findingProduct => {
        return findingProduct.product_schema_id === product.public_id
      })

      if (isRes) {
        dispatch(updateProduct(media, product.public_id))
        return
      }
      let purchaseIndex = reservingProducts.readyForSubmit[reservingProducts.selectedProductName].length
      const purchaseId = reservingProducts.purchases[reservingProducts.selectedProductName][purchaseIndex].public_id

      const data = {}
      data.product_schema_id = product.public_id
      data.parent_id = roomId
      data.parent_type = 1
      data.purchase_id = purchaseId
      data.product_video = media

      dispatch(addProductForSubmit(data))
    } else {
      dispatch(setImageToCroppie(media, props.index))
    }
  }

  useEffect(() => {

    const isActiveForUse =
      reservingProducts.purchases[reservingProducts.selectedProductName].length > reservingProducts.readyForSubmit[reservingProducts.selectedProductName].length
    const product = halls[reservingProducts.hallIndex].zones[reservingProducts.zoneIndex][reservingProducts.selectedProductName][props.index - 1]
    const isRes = reservingProducts.readyForSubmit[reservingProducts.selectedProductName].find(findingProduct => {
      return findingProduct.product_schema_id === product.public_id
    })
    setDragAndDrop({...dragAndDrop, activeForUse: isActiveForUse || !!isRes})
    let div = dropRef.current
    div.addEventListener('dragenter', handleDragIn)
    div.addEventListener('dragleave', handleDragOut)
    div.addEventListener('dragover', handleDrag)
    div.addEventListener('drop', handleDrop)

    return () => {
      div.removeEventListener('dragenter', handleDragIn)
      div.removeEventListener('dragleave', handleDragOut)
      div.removeEventListener('dragover', handleDrag)
      div.removeEventListener('drop', handleDrop)
    }
  }, [reservingProducts.draggingFileId, reservingProducts.hallIndex, reservingProducts.zoneIndex, reservingProducts.selectedProductName, reservingProducts.readyForSubmit])

  return (
    <div ref={dropRef} className={`${props.class} ${!dragAndDrop.activeForUse ? 'disabled' : ''}`}
         onClick={props.onClick}>
      {props.children}
    </div>
  )
}

export default DragAndDrop
