import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { localizeReducer } from 'react-localize-redux';

import uiReducer from './ui';
import authReducer from './auth';
import userReducer from './user';
import roomReducer from './room';
import searchReducer from './search'
import purchaseReducer from './purchase';
import confReducer from './conf'
import uploadFile from './uploadFile'
import products from './products'

const rootReducer = history =>
  combineReducers({
    uploadFile,
    ui: uiReducer,
    search: searchReducer,
    auth: authReducer,
    user: userReducer,
    room: roomReducer,
    conf: confReducer,
    purchase: purchaseReducer,
    router: connectRouter(history),
    localize: localizeReducer,
    products: products,
  });

export default rootReducer;
