import * as actionTypes from '../actions/products';

const initialState = {
  images: [],
  videos: [],
  isFilesPopupOpened: false,
  draggingFileId: undefined,
  selectedProductName: 'branding',
  readyForSubmit: {
    branding: [],
    runner: [],
    logo: [],
    monitor: []
  },
  purchases: {
    branding: [],
    runner: [],
    logo: [],
    monitor: []
  },
  videoThumbnails: [],
  hallIndex: 0,
  zoneIndex: 0,
  selectedProductId: 0,
  croppie: {
    sizes: {
      width: 0,
      height: 0
    },
    media: '',
    show: false,
    productIndex: 0
  }
};

const croppieSizes = {
  branding: {
    width: 1702,
    height: 603
  },
  runner: {
    width: 1024,
    height: 227
  },
  logo: {
    width: 940,
    height: 270
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_IMAGES: {
      return {
        ...state, images: [...state.images, ...action.images]
      };
    }
    case actionTypes.ADD_VIDEOS: {
      return {
        ...state, videos: [...state.videos, ...action.videos]
      };
    }
    case actionTypes.SORT_PURCHASE: {
      return {
        ...state, purchases: action.purchases, selectedProductName: action.firstPurchase
      }
    }
    case actionTypes.OPEN_CLOSE_FILES_POPUP: {
      return {
        ...state, isFilesPopupOpened: action.open, selectedProductId: action.id
      }
    }
    case actionTypes.DRAGGING_FILE_ID: {
      return {
        ...state, draggingFileId: action.id
      }
    }
    case actionTypes.PRODUCT_NAME: {
      return {
        ...state, selectedProductName: action.name
      }
    }
    case actionTypes.ADD_PRODUCT: {
      return {
        ...state,
        readyForSubmit: [...state.readyForSubmit, ...action.readyForSubmit]
      }
    }
    case actionTypes.SET_HALL_INDEX: {
      return {
        ...state, hallIndex: action.index, zoneIndex: 0
      }
    }
    case actionTypes.SET_ZONE_INDEX: {
      return {
        ...state, zoneIndex: action.index
      }
    }
    case actionTypes.UPDATE_PRODUCT: {
      const newState = {...state}
      const index = newState.readyForSubmit[newState.selectedProductName].findIndex(product => product.product_schema_id === action.publicId)
      if (newState.selectedProductName === 'monitor') {
        newState.readyForSubmit[newState.selectedProductName][index].product_video = action.media
      } else {
        newState.readyForSubmit[newState.selectedProductName][index].product_photo = action.media
      }
      newState.isFilesPopupOpened = false
      newState.selectedProductId = 0
      return {
        ...newState
      }
    }
    case actionTypes.ADD_PRODUCT_FOR_SUBMIT: {
      return {
        ...state,
        readyForSubmit: {
          ...state.readyForSubmit,
          [state.selectedProductName]: [...state.readyForSubmit[state.selectedProductName], action.product]
        },
        isFilesPopupOpened: false,
        selectedProductId: 0,
        croppie: initialState.croppie
      }
    }
    case actionTypes.SET_VIDEO_THUMBNAILS: {
      return {
        ...state,
        videoThumbnails: action.thumbnails
      }
    }
    case actionTypes.SET_IMAGE_TO_CROPPIE: {
      return {
        ...state,
        croppie: {
          ...state.croppie,
          media: action.media,
          show: true,
          sizes: croppieSizes[state.selectedProductName],
          productIndex: action.productIndex
        }
      }
    }
    case actionTypes.CLOSE_CROPPIE: {
      return {
        ...state, croppie: {...state.croppie, media: '', show: false}
      }
    }
    case actionTypes.CHANGE_CROPPIE_SIZES: {
      return {
        ...state, croppie: {...state.croppie, sizes: action.sizes}
      }
    }
    case actionTypes.UPDATE_RESERVING_PRODUCT_LIST: {
      return {
        ...state, readyForSubmit: action.products
      }
    }
    default:
      return state;
  }
};
