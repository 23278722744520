import React, { createContext, useContext, useReducer } from "react";
import { AuthContextReducer } from "../reducers/AuthContextReducer";

const initialState = {
  authToken: "",
  CSRFToken: ""
};

const initialAuthContext = {
  authDataState: initialState,
  setAuthDataState: () => {
  }
};

const AuthContext = createContext(initialAuthContext);

export function AuthContextProvider({ children }) {

  const [authDataState, setAuthDataState] = useReducer(AuthContextReducer, initialState);

  return (
    <AuthContext.Provider value={{ authDataState, setAuthDataState }}>
      {children}
    </AuthContext.Provider>
  );
}

export const UseAuthDataStateValue = () => useContext(AuthContext);
