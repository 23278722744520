import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotFound from '../../components/not-found';

import LoaderService from '../../services/loader-service';

class ErrorRoute extends Component {
  componentDidMount() {
    LoaderService.hideGroup('pageLoader');
  }

  componentDidUpdate() {
    LoaderService.hideGroup('pageLoader');
  }

  render() {
    return (
      <>
        <NotFound />
      </>
    );
  }
}

export default connect()(ErrorRoute);
