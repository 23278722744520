export const AuthContextReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_AUTH_TOKEN":
      localStorage.setItem("token", action.token);
      state.authToken = action.token;
      return {...state};
    case "UPDATE_CSRF_TOKEN":
      state.CSRFToken = action.token;
      return {...state};
    default:
      return state;
  }
};
