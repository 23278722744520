const month_names_short = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const month_names = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const DateHelper = {
  getFormat,
  calculatingDay,
  dateToString,
  getMonthsCount,
  getWeeksCount,
  getDaysCount,
  roundNumber,
  calculateAge,
  getAllYearsArray,
  convertDateToUTC,
  getDateYear,
  getDateMonthAndYear,
  getDateWhitStartTime,
  dateFormatYYYYMMDD,
}

function getFormat() {
  return 'yyyy-MM-dd';
}

function calculatingDay(startDay, daysCount) {
  return new Date(startDay.getTime() + (daysCount * 24 * 60 * 60 * 1000));
}

function getDateWhitStartTime(date) {
  return date.setHours(0, 0, 0)
}

function dateToString(date) {
  const dateObject = date;
  dateObject.setDate(dateObject.getDate());
  const year = dateObject.getFullYear();
  const day = dateObject.getDate();
  const mount = dateObject.getMonth();
  return `${month_names_short[mount]} ${day}, ${year}`;
}

function getMonthsCount(startDate, endDate) {
  let months;
  months = (endDate.getFullYear() - startDate.getFullYear() - 1) * 12;
  months += 12 - startDate.getMonth();
  months += endDate.getMonth() + 1;
  return months <= 0 ? 0 : months;
}

function getWeeksCount(startDate, endDate) {
  return Math.round((endDate.getTime() - startDate.getTime()) / (7 * 24 * 60 * 60 * 1000));
}

function getDaysCount(startDate, endDate) {
  return Math.round((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
}

function roundNumber(number, decimal) {
  const pow = Math.pow(10, decimal)
  return Math.round(number * pow) / pow
}

function calculateAge(date) {
  let minerDay = new Date();
  minerDay.setFullYear(minerDay.getFullYear() - 18);
  return minerDay.getTime() - date.getTime() <= 0
}

function getAllYearsArray() {

  const currentYear = new Date().getFullYear()

  const years = [];
  for (let i = 1900; i <= currentYear; i += 1) {
    years.push(i);
  }

  return years
}

function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()
  );
}

function getDateYear(date) {
  return date.getFullYear()
}

function getDateMonthAndYear(date) {
  const mount = date.getMonth();
  const year = date.getFullYear();
  return `${month_names[mount]} ${year}`
}

function dateFormatYYYYMMDD(date) {
  const year = date.getFullYear();
  const day = date.getDate();
  const mount = date.getMonth();
  return `${year}-${mount+1}-${day}`;
}
