import React, { createContext, useContext, useReducer } from "react";
import { ConferencesData } from "../reducers/ConferencesDataContextReducer";

const initialState = {
  errors: {
    speakerAvatar: false,
    speakerFiles: false
  },
  reservingConferenceData: {
    startDate: "",
    endDate: "",
    duration: "",
    subject: "Conference",
    type: "Conference",
  },
};

const initialConferencesDataContext = {
  conferencesDataState: initialState,
  setConferencesDataState: () => {
  }
};

const ConferencesDataContext = createContext(initialConferencesDataContext);

export function ConferencesDataContextProvider({ children }) {
  const [conferencesDataState, setConferencesDataState] = useReducer(ConferencesData, initialState);

  return <ConferencesDataContext.Provider
    value={{ conferencesDataState, setConferencesDataState }}>{children}</ConferencesDataContext.Provider>;
}

export const UseConferencesDataState = () => useContext(ConferencesDataContext);
