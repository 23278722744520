import React from 'react';
import PandaLogo from "../../assets/images/panda_logo.svg";
import NotFoundImg from "../../assets/images/not-found.svg";

const NotFound = () => {

  return (
    <div className={"floor-plan not-found"}>
      <div className={"floor-plan__header"}>
        <img src={PandaLogo} alt=""/>
      </div>
      <div className={"not-found__main"}>
        <h1>Ooops!</h1>
        <img src={NotFoundImg} alt=""/>
        <p>Something went wrong. Please contact our support and we will help you ASAP</p>
        <a href={'https://www.pandamr.com/contact-us'}>Contact Support</a>
      </div>
    </div>

  );
};

export default NotFound;
