import * as actionTypes from '../actions/search';

const initialState = {
    current: {
        loaded: true,
        error: null,
        data: null,
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case `${actionTypes.GET_SEARCH_LIST}_LOADING`: {
            return {
                ...state,
                current: {
                    ...state.current,
                    loaded: false,
                    error: null,
                },
            };
        }
        case `${actionTypes.GET_SEARCH_LIST}_SUCCESS`: {
            return {
                ...state,
                current: {
                    ...state.current,
                    loaded: true,
                    error: null,
                    data: action.payload.data,
                },
            };
        }

        case `${actionTypes.GET_SEARCH_LIST}_ERROR`: {
            return {
                ...state,
                current: {
                    ...state.current,
                    loaded: true,
                    error: action.payload,
                },
            };
        }
        default:
            return state;
    }
};
