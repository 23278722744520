import React from 'react';


const ErrorIndicator = () => {
  return (
    <div className="error-indicator">
      <h3 className="boom mb-0 text-danger">BOOOOOOM!</h3>
      <p className="text-white">something has gone terribly wrong</p>
    </div>
  );
};

export default ErrorIndicator;
