import React from 'react';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';

import AppRoutes from './routes';

const Root = props => (
  <ReduxProvider store={props.store}>
    <LocalizeProvider
      store={props.store}
      initialize={{
        languages: [
          { name: 'en', code: 'en' },
          { name: 'ru', code: 'ru' },
          { name: 'zh', code: 'zh' },
          { name: 'es', code: 'es' },
        ],
        options: {
          defaultLanguage: 'en',
          renderInnerHtml: false,
          renderToStaticMarkup,
          onMissingTranslation: ({ defaultTranslation }) => {
            return defaultTranslation.toLowerCase().includes('no default translation found') ? '' : defaultTranslation;
          },
        },
      }}>
      <AppRoutes history={props.history} />
    </LocalizeProvider>
  </ReduxProvider>
);

Root.propTypes = {
  store: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Root;
