import React, {useEffect, useState} from "react";
import {BigStand, MediumStand, SmallStand} from "./StandItem";
import {array_move} from "../../helpers/Utilities";
import {Carousel} from "react-responsive-carousel";
import {useSelector} from "react-redux";

const Zones = ({data, selectedId, colorIndex, ...props}) => {
  const zones = data.zones;
  const zonesLocation = [];
  const [selectedZoneIndex, setSelectedZoneIndex] = useState(0)
  const purchasedLabel = useSelector(state => {
    if (state.purchase.current.data[0]) {
      return state.purchase.current.data[0].purchased_label
    }
  })

  zones.map(zone => {
    const location = zone.location.sort((l1, l2) => {
      if (l1.index < l2.index) {
        return -1;
      } else if (l1.index > l2.index) {
        return 1;
      }
      return 0;
    });
    zonesLocation.push(location);
  });

  const zonesLocationScheme = {};

  zonesLocation.map((location, index) => {
    const scheme = [];
    scheme.push(location.filter(item => item.index === 2 || item.index === 3 || item.index === 4));
    scheme.push(location.filter(item => item.index === 7 || item.index === 6 || item.index === 5).reverse());
    scheme.push(location.filter(item => item.index === 10 || item.index === 9 || item.index === 8).reverse());
    let temp1_11 = location.filter(item => item.index === 12 || item.index === 11 || item.index === 1);
    scheme.push(array_move(temp1_11, 0, 2));
    scheme.push(location.filter(item => item.index === 13 || item.index === 14 || item.index === 15 || item.index === 16));
    scheme.push(location.filter(item => item.index === 20 || item.index === 19 || item.index === 18 || item.index === 17).reverse());
    zonesLocationScheme[`${index}`] = scheme;
  });

  const handleSelect = location => {
    if (!location.is_reserved && purchasedLabel === location.label) {
      props.onSelect(location.public_id);
    }
  };

  useEffect(()=>{
    setSelectedZoneIndex(0)
  }, [data])

  return (
    <>
      <Carousel
        axis={"horizontal"}
        infiniteLoop={false}
        showStatus={false}
        showIndicators={false}
        selectedItem={selectedZoneIndex}
        onChange={e => setSelectedZoneIndex(e)}
      >
        {Object.keys(zonesLocationScheme).map(item =>
          <div className={`map-zone map-zone_${colorIndex}`} key={Number(item)}>
            <div className={"map-zone__title"}>Zone {Number(item) + 1}</div>
            <div key={item} className={`map-zone_main`}>
              <div className="map-zone_start">
                {zonesLocationScheme[Number(item)][3].map(item1 =>
                  <MediumStand
                    key={item1.public_id}
                    item={item1}
                    colorIndex={colorIndex}
                    selectedId={selectedId}
                    handleSelect={handleSelect}
                    purchasedLabel={purchasedLabel}
                  />
                )}
              </div>
              <div className="map-zone_center">
                <div className="map-myCol-Big">
                  {zonesLocationScheme[Number(item)][2].map(item1 =>
                    <BigStand
                      key={item1.public_id}
                      item={item1}
                      colorIndex={colorIndex}
                      selectedId={selectedId}
                      handleSelect={handleSelect}
                      purchasedLabel={purchasedLabel}
                    />
                  )}
                </div>
                <div className="justContent">
                  {zonesLocationScheme[Number(item)][5].map(item1 =>
                    <SmallStand
                      key={item1.public_id}
                      item={item1}
                      isReverse={true}
                      colorIndex={colorIndex}
                      selectedId={selectedId}
                      handleSelect={handleSelect}
                      purchasedLabel={purchasedLabel}
                    />)}
                </div>
                <div className="map-myCol-Medium">
                  {zonesLocationScheme[Number(item)][4].map(item1 =>
                    <MediumStand
                      key={item1.public_id}
                      item={item1}
                      colorIndex={colorIndex}
                      selectedId={selectedId}
                      handleSelect={handleSelect}
                      purchasedLabel={purchasedLabel}
                    />)}
                </div>
                <div className="map-myCol-Big">
                  {zonesLocationScheme[Number(item)][0].map(item1 =>
                    <BigStand
                      key={item1.public_id}
                      item={item1}
                      colorIndex={colorIndex}
                      selectedId={selectedId}
                      handleSelect={handleSelect}
                      purchasedLabel={purchasedLabel}
                    />)}
                </div>
              </div>
              <div className="map-zone_start map-zone_end">
                {zonesLocationScheme[Number(item)][1].map(item1 =>
                  <SmallStand
                    key={item1.public_id}
                    item={item1}
                    colorIndex={colorIndex}
                    selectedId={selectedId}
                    handleSelect={handleSelect}
                    purchasedLabel={purchasedLabel}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Carousel>
      <div className={"carousel-indicator"}>
        {selectedZoneIndex+1}/{Object.keys(zonesLocationScheme).length}
      </div>
    </>

  );
};

export default Zones;
