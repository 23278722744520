import createAction from './create-action';
import ApiService from '../../services/api-service';

/**
 * ACTION TYPES
 */
export const GET_PURCHASE = 'GET_PURCHASE';
export const GET_PURCHASE_LIST = 'GET_PURCHASE_LIST';
/**
 * THUNKS
 */

export const getPurchase = query => dispatch => {
  return dispatch(
    createAction(
      GET_PURCHASE,
      new Promise((resolve, reject) => {
        ApiService.getPurchaseList(query)
          .then(payload => {
            if (!payload.data.length) {
              return reject({
                success: false,
                message: 'Purchase not found',
                data: null,
                errors: {},
              });
            }
            resolve(payload);
          })
          .catch(reject);
      }),
      {
        __PARSABLE_ERROR: true,
      }
    )
  );
};

export const getPurchaseList = query => dispatch => {
  return dispatch(
    createAction(GET_PURCHASE_LIST, ApiService.getPurchaseList(query), {
      __PARSABLE_ERROR: true,
    })
  );
};
