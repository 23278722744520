import React from "react";
import Loader from "./spinner";

const BigLoader = (props) => {

  return (
    <div className={`page-loader ${props.isLoaded ? "loaded" : ""}`}>
      <div className="loader-section section-left" />
      <div className="loader-section section-right" />
      <Loader />
    </div>
  );
};

export default BigLoader
