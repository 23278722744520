import React from 'react';
import classNames from 'classnames';
import { BigStand, MediumStand, SmallStand } from './StandItem'
import confImg from '../../assets/images/conf-img.png'
import { array_move } from '../../helpers/Utilities'
import smallPic from '../../assets/images/medium-plan.png'
import bigPic from '../../assets/images/orange_big.png'
import footerImg from '../../assets/images/footer.png'

const SmallMap = ({ data, selectedId, colorIndex, className, ...props }) => {
  const zone1 = data.zones.find(zn => zn.index === 1);
  const zone2 = data.zones.find(zn => zn.index === 2);
  const zone3 = data.zones.find(zn => zn.index === 3);

  const zone1_locations = zone1.locations.sort((l1, l2) => {
    if (l1.index < l2.index) {
      return -1;
    } else if (l1.index > l2.index) {
      return 1;
    }

    return 0;
  });

  const zone2_locations = zone2.locations.sort((l1, l2) => {
    if (l1.index < l2.index) {
      return -1;
    } else if (l1.index > l2.index) {
      return 1;
    }

    return 0;
  });

  const zone3_locations = zone3.locations.sort((l1, l2) => {
    if (l1.index < l2.index) {
      return -1;
    } else if (l1.index > l2.index) {
      return 1;
    }

    return 0;
  });


  const handleSelect = location => {
    if (!location.is_reserved && location.is_active_for_user) {
      props.onSelect(location.public_id);
    }
  };

  let zone1_3 = zone1_locations.filter(item => item.index === 2 ||
    item.index === 3 || item.index === 4)
  let zone1_4 = zone1_locations.filter(item => item.index === 7 ||
    item.index === 6 || item.index === 5).reverse()
  let zone1_7 = zone1_locations.filter(item => item.index === 10 ||
    item.index === 9 || item.index === 8).reverse()
  let temp1_11 = zone1_locations.filter(item => item.index === 12 ||
    item.index === 11 || item.index === 1)
  let zone1_11 = array_move(temp1_11, 0, 2)
  let zone1_13 = zone1_locations.filter(item => item.index === 13 ||
    item.index === 14 || item.index === 15 || item.index === 16)
  let zone1_16 = zone1_locations.filter(item => item.index === 20 ||
    item.index === 19 || item.index === 18 || item.index === 17).reverse()

  let zone2_3 = zone2_locations.filter(item => item.index === 2 ||
    item.index === 3 || item.index === 4)
  let zone2_4 = zone2_locations.filter(item => item.index === 7 ||
    item.index === 6 || item.index === 5).reverse()
  let zone2_7 = zone2_locations.filter(item => item.index === 10 ||
    item.index === 9 || item.index === 8).reverse()
  let temp2_11 = zone2_locations.filter(item => item.index === 12 ||
    item.index === 11 || item.index === 1)
  let zone2_11 = array_move(temp2_11, 0, 2)
  let zone2_13 = zone2_locations.filter(item => item.index === 13 ||
    item.index === 14 || item.index === 15 || item.index === 16)
  let zone2_16 = zone2_locations.filter(item => item.index === 20 ||
    item.index === 19 || item.index === 18 || item.index === 17).reverse()

  let zone3_3 = zone3_locations.filter(item => item.index === 2 ||
    item.index === 3 || item.index === 4)
  let zone3_4 = zone3_locations.filter(item => item.index === 7 ||
    item.index === 6 || item.index === 5).reverse()
  let zone3_7 = zone3_locations.filter(item => item.index === 10 ||
    item.index === 9 || item.index === 8).reverse()
  let temp3_11 = zone3_locations.filter(item => item.index === 12 ||
    item.index === 11 || item.index === 1)
  let zone3_11 = array_move(temp3_11, 0, 2)
  let zone3_13 = zone3_locations.filter(item => item.index === 13 ||
    item.index === 14 || item.index === 15 || item.index === 16)
  let zone3_16 = zone3_locations.filter(item => item.index === 20 ||
    item.index === 19 || item.index === 18 || item.index === 17).reverse()

  let myZone = {
    0: [zone1_3, zone1_4, zone1_7, zone1_11, zone1_13, zone1_16],
    1: [zone2_3, zone2_4, zone2_7, zone2_11, zone2_13, zone2_16],
    2: [zone3_3, zone3_4, zone3_7, zone3_11, zone3_13, zone3_16]
  }

  return (
    <div style={{ backgroundImage: `url(${!colorIndex && smallPic})` }} className={classNames(`map-container medium-map ${className}`)}>
      <div className="bordeGrey v-4 empty-f">
        {colorIndex !== undefined && <h2 className="hall-title">Orange Hall</h2>}
        <div className={`map-content ${colorIndex !== undefined && 'map-contentSmall'}`}>
          {/* <div onClick={() => props.isOpenConf(true, data.zones)} className='imgWrapper'>
          <img src={confImg} />
        </div> */}
          {Object.keys(myZone).map(item =>
            <div key={item} className={`map-zone map-zone-${Number(item) + 1}`}>
              <div className="map-row map-row-1">
                {myZone[Number(item)][3].map(item1 =>
                  <MediumStand
                    key={item1.public_id}
                    colorIndex={colorIndex}
                    item={item1}
                    selectedId={selectedId}
                    handleSelect={handleSelect} />
                )}
              </div>
              <div className='map-myCol'>
                <div className='map-myCol-Big'>
                  {myZone[Number(item)][2].map(item1 =>
                    <BigStand
                      key={item1.public_id}
                      colorIndex={colorIndex}
                      item={item1}
                      selectedId={selectedId}
                      handleSelect={handleSelect} />
                  )}
                </div>
                <div className='justContent'>
                  {myZone[Number(item)][5].map(item1 =>
                    <SmallStand
                      key={item1.public_id}
                      item={item1}
                      isReverse={true}
                      colorIndex={colorIndex}
                      selectedId={selectedId}
                      handleSelect={handleSelect}
                    />)}
                </div>
                <div className='map-myCol-Medium'>
                  {myZone[Number(item)][4].map(item1 =>
                    <MediumStand
                      key={item1.public_id}
                      item={item1}
                      colorIndex={colorIndex}
                      selectedId={selectedId}
                      handleSelect={handleSelect}
                    />)}
                </div>
                <div className='map-myCol-Big'>
                  {myZone[Number(item)][0].map(item1 =>
                    <BigStand
                      key={item1.public_id}
                      item={item1}
                      colorIndex={colorIndex}
                      selectedId={selectedId}
                      handleSelect={handleSelect} />)}
                </div>
              </div>
              <div className="map-row map-row-4">
                {myZone[Number(item)][1].map(item1 =>
                  <SmallStand
                    key={item1.public_id}
                    item={item1}
                    colorIndex={colorIndex}
                    selectedId={selectedId}
                    handleSelect={handleSelect}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className='footerBig'><img src={footerImg} alt='' /></div>
      </div>
    </div>
  );
};

export default SmallMap;
