import React, {useEffect, useState} from "react";
import Zones from "./zones";
import {connect, useSelector} from "react-redux";

const StandCustomMap = props => {
  const [state, setState] = useState({
    index: 0,
    arr: [],
    temp: 0
  });
  let interval;

  useEffect(() => {
    if (state.arr.length <= 5 && state.temp !== 1) {
      interval = setInterval(() => {
        setState({
          ...state,
          arr: [...state.arr, "size"]
        });
        return clearInterval(interval);
      }, 300);
    }

    if (state.arr.length === 5) {
      clearInterval(interval);
      setState({
        ...state,
        arr: [],
        temp: 1
      });
    }
  }, []);

  return (
    <>
      <div className={`halls__header ${props.data.halls.length === 1 ? 'hide' : ''}`}>
        {props.data.halls.length > 1 ? props.data.halls.map((data, i) =>
          <div onClick={() => setState({...state, index: i})} key={i}
               className={`halls__header_hall hall_back_${i} ${state.index === i ? 'selected' : ''}`}
          >
            {i === 0 ? <p>Red Hall</p> :
              i === 1 ? <p>Blue Hall</p> :
                i === 2 ? <p>Green Hall</p> :
                  i === 3 && <p>Orange Hall</p>}
            <div className={`hall_arrow hall_border_${i} ${state.index === i ? 'show' : ''}`}/>
          </div>
        ) : ''}
      </div>
      <div className={"halls__zones"}>
        <Zones
          data={props.data.halls[state.index]}
          colorIndex={state.index}
          onSelect={props.onSelect}
          selectedId={props.selectedId}
        />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(StandCustomMap);
