// import * as errorCodes from '../constants/error-codes';

class ErrorService {
  parse = data => {
    const fields = this.parseFields(data);
    const message = this.parseMessage(data);

    return {
      fields,
      message,
      code: data.error_code,
    };
  };

  parseFields = data => {
    return data.fields;
  };

  parseMessage = data => {
    // switch (data.error_code) {
    //   case errorCodes.NOT_FOUND.code:
    //     return errorCodes.NOT_FOUND.message;
    //
    //   default:
    //     return data.translation_key;
    // }

    return data.translation_key;
  };
}

const errorService = new ErrorService();

export { ErrorService };

export default errorService;
