import * as actionTypes from '../actions/auth';

import isEmpty from 'lodash/isEmpty';

import qs from 'qs';

function newDecoder(parseNumbers = true, parseBool = true, ignoreNull = true, ignoreEmptyString = true) {
  return function decoder(str, decoder, charset) {
    const strWithoutPlus = str.replace(/\+/g, ' ');
    if (charset === 'iso-8859-1') {
      // unescape never throws, no try...catch needed:
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }

    if (parseNumbers && /^(\d+|\d*\.\d+)$/.test(str)) {
      return parseFloat(str);
    }

    if (ignoreEmptyString && str.length === 0) {
      return;
    }

    const keywords = {
      null: ignoreNull ? undefined : null,
      undefined,
    };

    if (str in keywords) {
      return keywords[str];
    }

    const boolKeywords = {
      true: true,
      false: false,
    };

    if (parseBool && str in boolKeywords) {
      return boolKeywords[str];
    }

    try {
      return decodeURIComponent(strWithoutPlus);
    } catch (e) {
      return strWithoutPlus;
    }
  };
}

const queryString = window.location.search.slice(1) || window.location.hash.split('?')[1];

let assignedData = qs.parse(queryString, { decoder: newDecoder() });

if (!isEmpty(assignedData)) {
  // assignedData.playerID = assignedData.playerID.toString();
  // assignedData.currencyID = assignedData.currencyId || 'AMD';
} else assignedData = null;

const initialState = {
  loaded: true,
  error: null,
  data: assignedData,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${actionTypes.VERIFY_TOKEN}_LOADING`: {
      return {
        ...state,
        error: null,
        loaded: false,
      };
    }

    case `${actionTypes.VERIFY_TOKEN}_ERROR`: {
      return {
        ...state,
        data: null,
        error: null,
        loaded: true,
      };
    }
    case `${actionTypes.VERIFY_TOKEN}_SUCCESS`: {
      return {
        ...state,
        data: !isEmpty(assignedData) ? { ...action.payload.data, token: assignedData.token } : action.payload.data,
        error: null,
        loaded: true,
      };
    }
    default:
      return state;
  }
};
