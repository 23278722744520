import * as actionTypes from '../actions/room';

const initialState = {
  current: {
    loaded: true,
    error: null,
    data: null,
  },
  list: {
    loaded: true,
    error: null,
    data: [],
  },
  expoList: {
    loaded: true,
    error: null,
    data: [],
  },
  designList: {
    loaded: true,
    error: null,
    data: [],
  },
  locationList: {
    loaded: true,
    error: null,
    data: [],
  },
  location: {
    loaded: true,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    /* RESET PART */
    case `${actionTypes.RESET_ROOM}`: {
      return {
        ...state,
        current: {
          ...state.current,
          // data: null,
          // loaded: true,
          error: null,
        },
      };
    }

    case `${actionTypes.RESET_ROOM_LIST}`: {
      return {
        ...state,
        list: {
          data: [],
          loaded: true,
          error: null,
        },
      };
    }

    case `${actionTypes.RESET_EXPO_LIST}`: {
      return {
        ...state,
        expoList: {
          data: [],
          loaded: true,
          error: null,
        },
      };
    }

    /*  GET ROOM LIST PART  */
    case `${actionTypes.GET_ROOM_LIST}_LOADING`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_ROOM_LIST}_SUCCESS`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }
    case `${actionTypes.GET_ROOM_LIST}_ERROR`: {
      return {
        ...state,
        list: {
          ...state.list,
          loaded: true,
          error: action.payload,
        },
      };
    }
    /*  GET EXPO LIST PART  */
    case `${actionTypes.GET_EXPO_LIST}_LOADING`: {
      return {
        ...state,
        expoList: {
          ...state.expoList,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_EXPO_LIST}_SUCCESS`: {
      return {
        ...state,
        expoList: {
          ...state.expoList,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }
    case `${actionTypes.GET_EXPO_LIST}_ERROR`: {
      return {
        ...state,
        expoList: {
          ...state.expoList,
          loaded: true,
          error: action.payload,
        },
      };
    }

    /*  GET ROOM PART  */
    case `${actionTypes.GET_ROOM}_LOADING`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_ROOM}_SUCCESS`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }
    case `${actionTypes.UPDATE_ROOM_DATA}_SUCCESS`: {
      console.log(action.payload.data);
      return {
        ...state,
        current: {
          ...state.current,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }

    case `${actionTypes.GET_ROOM}_ERROR`: {
      return {
        ...state,
        current: {
          ...state.current,
          loaded: true,
          error: action.payload,
        },
      };
    }

    /*  GET design list PART  */
    case `${actionTypes.GET_DESIGN_LIST}_LOADING`: {
      return {
        ...state,
        designList: {
          ...state.designList,
          loaded: false,
          error: null,
        },
      };
    }
    case `${actionTypes.GET_DESIGN_LIST}_SUCCESS`: {
      return {
        ...state,
        designList: {
          ...state.designList,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }

    case `${actionTypes.GET_DESIGN_LIST}_ERROR`: {
      return {
        ...state,
        designList: {
          ...state.designList,
          loaded: true,
          error: action.payload,
        },
      };
    }

    /*  GET location list PART  */
    case `${actionTypes.GET_LOCATION_LIST}_LOADING`: {
      return {
        ...state,
        locationList: {
          ...state.locationList,
          loaded: false,
          error: null,
        },
      };
    }

    case `${actionTypes.GET_LOCATION_LIST}_SUCCESS`: {
      return {
        ...state,
        locationList: {
          ...state.locationList,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }

    case `${actionTypes.GET_LOCATION_LIST}_ERROR`: {
      return {
        ...state,
        locationList: {
          ...state.locationList,
          loaded: true,
          error: action.payload,
        },
      };
    }

    /*  GET location list PART  */

    case `${actionTypes.CREATE_LOCATION}_LOADING`:
    case `${actionTypes.DELETE_LOCATION}_LOADING`:
    case `${actionTypes.UPDATE_LOCATION}_LOADING`:
    case `${actionTypes.GET_LOCATION}_LOADING`: {
      return {
        ...state,
        location: {
          ...state.location,
          loaded: false,
          error: null,
        },
      };
    }

    case `${actionTypes.GET_LOCATION}_SUCCESS`: {
      return {
        ...state,
        location: {
          ...state.location,
          loaded: true,
          error: null,
          data: action.payload.data,
        },
      };
    }

    case `${actionTypes.CREATE_LOCATION}_SUCCESS`:
    case `${actionTypes.DELETE_LOCATION}_SUCCESS`:
    case `${actionTypes.UPDATE_LOCATION}_SUCCESS`: {
      return {
        ...state,
        location: {
          ...state.location,
          loaded: true,
          error: null,
        },
      };
    }

    case `${actionTypes.CREATE_LOCATION}_ERROR`:
    case `${actionTypes.DELETE_LOCATION}_ERROR`:
    case `${actionTypes.UPDATE_LOCATION}_ERROR`:
    case `${actionTypes.GET_LOCATION}_ERROR`: {
      return {
        ...state,
        location: {
          ...state.location,
          loaded: true,
          error: action.payload,
        },
      };
    }

    /* CREATE AND DELETE ROOM PART */

    case `${actionTypes.CREATE_ROOM}_LOADING`:
    case `${actionTypes.DELETE_ROOM}_LOADING`: {
      return {
        ...state,
        current: {
          ...state.current,
          data: null,
          loaded: false,
          error: null,
        },
      };
    }

    case `${actionTypes.CREATE_ROOM}_SUCCESS`:
    case `${actionTypes.DELETE_ROOM}_SUCCESS`: {
      return {
        ...state,
        current: {
          ...state.current,
          data: null,
          loaded: true,
          error: null,
        },
      };
    }

    case `${actionTypes.CREATE_ROOM}_ERROR`:
    case `${actionTypes.DELETE_ROOM}_ERROR`: {
      return {
        ...state,
        current: {
          ...state.current,
          data: null,
          loaded: true,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
