import * as actionTypes from '../actions/uploadFile';

const initialState = {
    loading: null,
    data: {
        conf: {
            video: [],
            image: [],
            pdf: []
        },
        panel: {
            order: 0,
            video: [],
            image: [],
            pdf: []
        }
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case `${actionTypes.UPLOAD_FILE_LOADED}`: {
            return {
                ...state,
                loading: true
            }
        }
        case `${actionTypes.UPLOAD_FILE}`: {
            if (action.payload.data.isConf) {
                return {
                    // action.payload.data
                    ...state,
                    error: null,
                    // ...state.data,
                    //     conf: {
                    //         ...state.data.conf,
                    //         video: [...state.data.conf.video, ...action.payload.data.video],
                    //         image: [...state.data.conf.image, ...action.payload.data.image],
                    //         pdf: [...state.data.conf.pdf, ...action.payload.data.pdf],
                    //     }
                    data: {
                        ...state.data,
                        conf: {
                            ...state.data.conf,
                            video: action.payload.data.video !== undefined ? state.data.conf.video.concat(action.payload.data.video) : [],
                            image: action.payload.data.image !== undefined ? state.data.conf.image.concat(action.payload.data.image) : [],
                            pdf: action.payload.data.pdf !== undefined ? state.data.conf.pdf.concat(action.payload.data.pdf) : [],
                            // image: [...state.data.conf.image, ...action.payload.data.image],
                            // pdf: [...state.data.conf.pdf, ...action.payload.data.pdf],
                        }
                    },
                    loading: false
                };
            } else {
                return {
                    ...state,
                    error: null,
                    data: {
                        ...state.data,
                        panel: action.payload.data
                    },
                    loading: false
                };
            }
        }
        case `${actionTypes.UPLOAD_FILE_RESET}`: {
            return initialState
        }
        case `${actionTypes.UPLOAD_FILE_ERROR}`: {
            console.log(action, 'act')
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        }
        default:
            return state;
    }
};
