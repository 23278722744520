import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getRoom, updateLocation} from '../../store/actions/room';
import {
  addProductForSubmit,
  closeCroppie,
  openFilesPopup,
  setHallIndex,
  setProductName,
  sortPurchase,
  updateProduct
} from '../../store/actions/products';
import ErrorBoundary from '../../components/error-boundary';
import Spinner from '../../components/loader';
import PandaLogo from "../../assets/images/panda_logo.svg";
import CustomMap from "../../components/products/Maps/customMap";
import FilesManager from "../../components/products/filesManager";
import FilesPopup from "../../components/products/filesPopup";
import {CroppieModal} from "../../components/modal";

const AdvertisingRoute = (props) => {

  const dispatch = useDispatch()
  const selectedProductName = useSelector(state => state.products.selectedProductName)
  const hallIndex = useSelector(state => state.products.hallIndex)
  const reservingProducts = useSelector(state => state.products)

  const [state, setState] = useState({
    openedLocationId: null,
    formData: {
      locationId: null,
      bannerFile: null,
    },
  });


  const handleDataUpdate = locationId => {
    setState({
      ...state,
      formData: {
        ...state.formData,
        locationId
      },
    });
  };

  const handleProductTitleClick = (productName) => {
    dispatch(setProductName(productName))
  }

  const getProductPurchaseCount = (label) => {
    const productByLabel = props.purchase.data.filter(product => product.product === label)
    return productByLabel.length
  }

  const getProductReserveCount = (name) => {
    return reservingProducts.readyForSubmit[name].length
  }

  const changeHallIndex = (index) => {
    dispatch(setHallIndex(index))
  }


  const saveImage = (media) => {

    const halls = props.room.data.halls
    const roomId = props.room.data.room_id
    const product = halls[reservingProducts.hallIndex].zones[reservingProducts.zoneIndex][reservingProducts.selectedProductName][reservingProducts.croppie.productIndex - 1]
    const isRes = reservingProducts.readyForSubmit[reservingProducts.selectedProductName].find(findingProduct => {
      return findingProduct.product_schema_id === product.public_id
    })
    if (isRes) {
      dispatch(updateProduct(media, product.public_id))
      return
    }
    let purchaseIndex = reservingProducts.readyForSubmit[reservingProducts.selectedProductName].length
    const purchaseId = reservingProducts.purchases[reservingProducts.selectedProductName][purchaseIndex].public_id

    const data = {}
    data.product_schema_id = product.public_id
    data.parent_id = roomId
    data.parent_type = 1
    data.purchase_id = purchaseId

    if (reservingProducts.selectedProductName === 'monitor') {
      data.product_video = media
    } else {
      data.product_photo = media
    }

    dispatch(addProductForSubmit(data))
  }

  useEffect(() => {
    const {roomId} = props.data;
    props.getRoom(roomId, {extra_info: true});
  }, [])

  useEffect(() => {
    const sortedPurchases = {
      branding: [],
      runner: [],
      logo: [],
      monitor: []
    }

    let firstPurchase = 'monitor'

    props.purchase.data.forEach(purchase => {
      switch (purchase.product) {
        case 1:
          sortedPurchases.monitor.push(purchase)
          return
        case 3:
          if (firstPurchase === 'monitor' || firstPurchase === 'logo' || firstPurchase === 'runner') {
            firstPurchase = 'branding'
          }
          sortedPurchases.branding.push(purchase)
          return
        case 4:
          if (firstPurchase === 'monitor') {
            firstPurchase = 'logo'
          }
          sortedPurchases.logo.push(purchase)
          return
        case 5:
          if (firstPurchase === 'monitor' || firstPurchase === 'logo') {
            firstPurchase = 'runner'
          }
          sortedPurchases.runner.push(purchase)
          return
        default:
          return;
      }
    })
    if (sortedPurchases[selectedProductName].length) {
      dispatch(sortPurchase(sortedPurchases, selectedProductName))
    } else {
      dispatch(sortPurchase(sortedPurchases, firstPurchase))
    }
  }, [props.purchase])

  const {room, isLoaded} = props;

  return (
    <ErrorBoundary>
      {isLoaded ?
        <>
          <div className="floor-plan-wrap">
            <div className="floor-plan-wrap-left">
              <div className="floor-plan">
                <div className={"floor-plan__header"}>
                  <img src={PandaLogo} alt=""/>
                </div>
                <div className="floor-plan-content">
                  <div className={"floor-plan__main"}>
                    <div className={"floor-plan__title"}>
                      <h2>{room.data.room.name} Floor Plan</h2>
                      <div className="reminder-block">
                        <p>Remainder</p>
                        <span
                          className="remainder-count">{props.purchase.data.length - getProductPurchaseCount(0) - getProductPurchaseCount(2)}</span>
                      </div>
                    </div>
                    <div className={"floor-plan__main_halls advertising-plan__main_halls"}>
                      <div className="floor-plan-main-halls-inner">
                        <div className={`floor-nav-bar show-product-${selectedProductName}`}>
                          <div className={`floor-nav-bar-inner ${!getProductPurchaseCount(3) ? 'disabled' : ''}`}
                               onClick={() => handleProductTitleClick('branding')}>
                            <div className="floor-nav-bar-i-c">
                              <i className="ic_branding">
                                <span>{getProductPurchaseCount(3) - getProductReserveCount('branding')}</span>
                              </i>
                              <p className="nav-bar-i-title">branding</p>
                            </div>
                          </div>
                          <div className={`floor-nav-bar-inner ${!getProductPurchaseCount(5) ? 'disabled' : ''}`}
                               onClick={() => handleProductTitleClick('runner')}>
                            <div className="floor-nav-bar-i-c">
                              <i className="ic_runner">
                                <span>{getProductPurchaseCount(5) - getProductReserveCount('runner')}</span>
                              </i>
                              <p className="nav-bar-i-title">runner banner</p>
                            </div>
                          </div>
                          <div className={`floor-nav-bar-inner ${!getProductPurchaseCount(4) ? 'disabled' : ''}`}
                               onClick={() => handleProductTitleClick('logo')}>
                            <div className="floor-nav-bar-i-c">
                              <i className="ic_logo">
                                <span>{getProductPurchaseCount(4) - getProductReserveCount('logo')}</span>
                              </i>
                              <p className="nav-bar-i-title">logo</p>
                            </div>
                          </div>
                          <div className={`floor-nav-bar-inner ${!getProductPurchaseCount(1) ? 'disabled' : ''}`}
                               onClick={() => handleProductTitleClick('monitor')}>
                            <div className="floor-nav-bar-i-c">
                              <i className="ic_monitor">
                                <span>{getProductPurchaseCount(1) - getProductReserveCount('monitor')}</span>
                              </i>
                              <p className="nav-bar-i-title">monitor</p>
                            </div>
                          </div>
                        </div>
                        <div className={`halls__header ${room.data.halls.length === 1 ? 'hide' : ''}`}>
                          {room.data.halls.length > 1 ? room.data.halls.map((data, i) =>
                            <div onClick={() => changeHallIndex(i)} key={i}
                                 className={`halls__header_hall hall_back_${i} ${hallIndex === i ? 'selected' : ''}`}
                            >
                              {i === 0 ? <p>Red Hall</p> :
                                i === 1 ? <p>Blue Hall</p> :
                                  i === 2 ? <p>Green Hall</p> :
                                    i === 3 && <p>Orange Hall</p>}
                              <div className={`hall_arrow hall_border_${i} ${hallIndex === i ? 'show' : ''}`}/>
                            </div>
                          ) : ''}
                        </div>
                        <div className={"halls__zones"}>
                          <div className="halls-zones-inner">
                            {room.data.halls[hallIndex].zones.length ?
                              <CustomMap
                                data={room.data.halls[hallIndex]}
                                colorIndex={hallIndex}
                                onSelect={handleDataUpdate}
                                selectedId={state.formData.locationId}
                              />
                              : ''
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`floor-plan-wrap-right`}>
              <div className={"advertising__submit"}>
                <FilesManager
                  formData={state.formData}
                  onSave={props.updateProduct}
                  purchaseId={props.data.purchaseId}
                  expoId={props.auth.data.expo_id}
                />
              </div>
            </div>
            <div className={`popup-wrap ${props.product.isFilesPopupOpened ? 'show' : ''}`}>
              <FilesPopup/>
            </div>
            <div className={`advertising__croppie`}>
              <CroppieModal
                data={{ file:reservingProducts.croppie.media, ...reservingProducts.croppie.sizes}}
                closeModal={() => {dispatch(closeCroppie())}}
                onSave={(media) => saveImage(media)}
                isOpen={props.product.croppie.show}
              />
            </div>
          </div>
        </>
        :
        <Spinner/>
      }
    </ErrorBoundary>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    product: state.products,
    purchase: state.purchase.current,
    room: state.room.current,
    roomLocationList: state.room.locationList,
    isLoaded:
      state.auth.data &&
      state.auth.loaded &&
      state.room.current.data &&
      state.room.current.loaded
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoom: (id, query) => dispatch(getRoom(id, query)),
    updateProduct: (data) => dispatch(updateLocation(data)),
    openFilesPopup: (open) => dispatch(openFilesPopup(open))
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(AdvertisingRoute);
