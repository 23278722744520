import React from "react";
import axiosWrapper from "../axiosWrapper";

export default {
  getConferenceByExpoId(expo_id) {
    const url = `/conference_halls/?expo_id=${expo_id}`;
    return axiosWrapper({ method: 'get', url: url });
  },

  getConferenceByDay(id, date) {
    const url = `/conference_halls/${id}/?&date=${date}&is_current=true`
    return axiosWrapper({ method: 'get', url: url})
  },

  getSpeakersData(public_id) {
    const url = `/conference_halls/reservations/${public_id}/`
    return axiosWrapper({ method: 'get', url: url})
  },

  submitConference(formData) {
    const url = `/conference_halls/reservations/`
    return axiosWrapper({ method: 'post', url: url, body: formData})
  }
};
