import React, {useEffect, useRef, useState} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import PandaLogo from '../../assets/images/panda_logo.svg'
import searchIconLight from '../../assets/images/search.svg'
import closeIconCircle from '../../assets/images/close-icon-circle.svg'
import {getRoom, updateLocation} from '../../store/actions/room';
import ErrorBoundary from '../../components/error-boundary';
import Spinner from '../../components/loader';
import {getSearchList} from '../../store/actions/search';
import {StandCustomMap} from "../../components/locations-map";
import StandDesign from "./standDesigns";
import closeIconPopUp from '../../assets/images/close_icon_popup.svg'
import StandUpload from "./standUpload/standUpload";
import useKeypress from "react-use-keypress";

const StandContainer = (props) => {
  const [state, setState] = useState({
    searchVal: '',
    showSearch: null,
    searchResult: null,
    submitStep: 0,
    formData: {
      locationId: null,
      designId: null,
    },
  });
  const submitBox = useRef(null);
  const handleAreaSelect = locationId => {
    setState({
      ...state,
      submitStep: 1,
      formData: {
        ...state.formData,
        locationId: locationId
      }
    })
  };

  const handleClose = () => {
    setState({
      ...state,
      submitStep: 0,
      formData: {
        ...state.formData,
        locationId: null
      }
    })
  };

  const changeStep = (step) => {
    setState({
      ...state,
      submitStep: step,
    })
  }

  const handleChangeSearch = e => {
    let arr = state.searchResult
    const length = e.target.value.length
    let arr1 = arr.filter(item => item.reserved_by.office?.substr(0, length).toLowerCase() === e.target.value.toLowerCase())
    setState({...state, showSearch: arr1, searchVal: e.target.value})
  }

  const handleDataUpdate = data => {
    setState(state => ({
      ...state,
      submitStep: 2,
      formData: {
        ...state.formData,
        ...data,
      },
    }));
  };
  useEffect(() => {

    const listener = (event) => {
      if (event.target.className === 'close-box') {
        handleClose()
      }
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [submitBox]);

  useKeypress('Escape', handleClose);

  useEffect(() => {
    const {roomId} = props.data;
    const query = {
      extra_info: true,
    }
    props.getRoom(roomId, query);
    props.makeSearch(props.data.roomId).then(payload => {
      setState({...state, searchResult: payload.value.data})
    })
  }, [])

  const {formData, showSearch} = state;
  const {room, purchase, isLoaded} = props;

  return (
    <ErrorBoundary>
      {isLoaded ?
        <>
          <div className={"floor-plan"}>
            <div className={"floor-plan__header"}>
              <img src={PandaLogo} alt=""/>
            </div>
            <div className={"floor-plan__main"}>
              <div className={"floor-plan__title"}>
                <h2>{room.data.room.name} Floor Plan</h2>
                <p>Now choose the best place for your stand</p>
              </div>
              <div className={"floor-plan__main_search"}>
                <img src={searchIconLight} alt=""/>
                <input
                  onChange={e => handleChangeSearch(e)}
                  value={state.searchVal}
                  placeholder='Search...'
                />
                <div className={`close-icon ${state.searchVal !== '' ? 'show' : ''}`}
                     onClick={() => setState({...state, searchVal: '', showSearch: null})}>
                  <img src={closeIconCircle} alt=''/>
                </div>
                {state.searchVal &&
                <div className={"search__result"}>
                  {showSearch !== null ?
                    <>
                      {showSearch.length > 0 && <p className={"search__result_title"}>Exhibitors</p>}
                      {showSearch.length === 0 ?
                        <p className={"search__result_no-result"}>No Results</p> :
                        <div className={"search__result_items"}>
                          {showSearch.map(item =>
                            <div key={item.public_id} className={"search__result_item"}>
                              <p className={"search__result_item_name"}>{item.reserved_by.office}</p>
                              <span/>
                              <div className={"search__result_item_info"}>
                                <h5>{item.hall === 1 ? 'R-' : item.hall === 2 ? 'B-' : item.hall === 3 ? 'G-' : 'O-'}{item.index}</h5>
                                <p>Zone-{item.zone}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </>
                    :
                    <Spinner/>
                  }
                </div>}
              </div>
              <div className={"floor-plan__main_halls"}>
                <StandCustomMap
                  data={room.data}
                  selectedId={formData.locationId}
                  onSelect={handleAreaSelect}
                />
              </div>
            </div>
            <div className={`floor-plan__submit ${state.submitStep ? 'show' : ''}`}>
              <div className={'close-box'}/>
              <div className={"submit-form"} ref={submitBox}>
                <div className={"submit-close-button"} onClick={handleClose}>
                  <img src={closeIconPopUp} alt=""/>
                </div>
                {state.submitStep === 1 ?
                  <StandDesign
                    data={{productLabel: purchase.data[0]?.purchased_label, locationId: state.formData.locationId}}
                    onSave={handleDataUpdate}/>
                  : state.submitStep === 2 ?
                    <StandUpload
                      formData={state.formData}
                      changeStep={changeStep}
                      onSave={props.updateProduct}
                      purchaseId={props.data.purchaseId}
                      expoId={props.auth.data.expo_id}
                    />
                    : ""
                }
              </div>
            </div>
          </div>
        </>
        :
        <Spinner/>
      }
    </ErrorBoundary>
  );
}

function mapStateToProps(state) {

  return {
    auth: state.auth,
    product: state.room.location,
    purchase: state.purchase.current,
    room: state.room.current,
    roomLocationList: state.room.locationList,
    isLoadedSearch: state.search.current.loaded,
    search: state.search.current.data,
    isLoaded:
      state.auth.data &&
      state.auth.loaded &&
      state.room.current.data &&
      state.room.current.loaded &&
      state.search.current.loaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoom: (id, query) => dispatch(getRoom(id, query)),
    makeSearch: (id, query) => dispatch(getSearchList(id, query)),
    updateProduct: (data) => dispatch(updateLocation(data)),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withLocalize
);

export default enhance(StandContainer);
