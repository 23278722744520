import createAction from './create-action';
import ApiService from '../../services/api-service';

export const RESET_CONF_DATE = 'RESET_CONF_DATE';

export const RESET_CONF_HOURS = 'RESET_CONF_HOURS';

export const RESET_CONF = 'RESET_CONF';

export const GET_CONF = 'GET_CONF';

export const CONF_DURATION = 'CONF_DURATION';

export const GET_SPEAKER = 'GET_SPEAKER';

export const PUT_SPEAKER = 'PUT_SPEAKER'

export const RESERVATION = "RESERVATION";

export const UPLOAD_FILES = 'UPLOAD_FILES';

export const resetConfDate = () => dispatch => dispatch(createAction(RESET_CONF_DATE));

export const resetConfHours = () => dispatch => dispatch(createAction(RESET_CONF_HOURS));

export const resetConf = () => dispatch => dispatch(createAction(RESET_CONF));

export const getConferance = id => dispatch => {
    return dispatch(
        createAction(GET_CONF, ApiService.getConferance(id), {
            __PARSABLE_ERROR: true,
        })
    );
};

export const conferanceDuration = (id, min, date) => dispatch => {
    return dispatch(
        createAction(CONF_DURATION, ApiService.confDuration(id, min, date), {
            __PARSABLE_ERROR: true,
        })
    );
};

export const isAgenda = id => dispatch => {
    return dispatch(
        createAction(CONF_DURATION, ApiService.isAgenda(id), {
            __PARSABLE_ERROR: true,
        })
    );
};

export const isAgendaDuration = (id, min, date) => dispatch => {
    return dispatch(
        createAction(CONF_DURATION, ApiService.isAgendaDuration(id, min, date), {
            __PARSABLE_ERROR: true,
        })
    );
};

export const getSpeaker = id => dispatch => {
    return dispatch(
        createAction(GET_SPEAKER, id != undefined ? ApiService.getSpeakerConf(id) : {}, {
            __PARSABLE_ERROR: true,
        })
    );
};

export const putSpeaker = () => dispatch => {
    return dispatch(
        createAction(PUT_SPEAKER, {}, {
            __PARSABLE_ERROR: true,
        })
    );
};

export const reservConf = data => dispatch => {
    return dispatch(
        createAction(RESERVATION, ApiService.reservConference(data), {
            __PARSABLE_ERROR: true,
        })
    );
};

// function getCookie(name) {
//     var cookieValue = null;
//     if (document.cookie && document.cookie !== '') {
//         var cookies = document.cookie.split(';');
//         for (var i = 0; i < cookies.length; i++) {
//             var cookie = jQuery.trim(cookies[i]);
//             if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//                 break;
//             }
//         }
//     }
//     return cookieValue;
// }

// export const uploadFiles = (url, data) => {
//     // console.log(data)
//     return dispatch => {
//         fetch(url, {
//             method: 'POST',
//             // headers: {
//             //     // "X-CSRFToken": getCookie('csrftoken')
//             //     // "Content-type": "multipart/form-data; boundary=----WebKitFormBoundaryLfMgGGnAcQoQZzU0",
//             //     // "Content-Type": "multipart/form-data"
//             //     // "Content-Disposition": `attachment; name="video"; filename=${data[0].photo.name}`
//             //     // "filename*"
//             // },
//             body: data
//         }).then(response => response.json())
//             .then(files => {
//                 // console.log(files, 'response')
//                 dispatch({
//                     type: UPLOAD_FILES,
//                     files
//                 })
//             })
//             .catch(error => {
//             })
//     }
// }

// export const uploadFiles = data => dispatch => {
//     return dispatch(
//         createAction(UPLOAD_FILES, ApiService.uploadFiles(data), {
//             __PARSABLE_ERROR: true,
//         })
//     );
// };

// export const conferanceSuccess = (date, id) => dispatch => {
//     return dispatch(
//         createAction(CONF_SUCCESS, ApiService.confDate(date, id), {
//             __PARSABLE_ERROR: true,
//         })
//     );
// };

