import createAction from './create-action';
import ApiService from '../../services/api-service';

/**
 * ACTION TYPES
 */

export const RESET_EXPO_LIST = 'RESET_EXPO_LIST';

export const RESET_ROOM_LIST = 'RESET_ROOM_LIST';

export const RESET_ROOM = 'RESET_ROOM';

export const GET_EXPO_LIST = 'GET_EXPO_LIST';

export const GET_ROOM_LIST = 'GET_ROOM_LIST';

export const GET_ROOM = 'GET_ROOM';

export const UPDATE_ROOM_DATA = 'UPDATE_ROOM_DATA';

export const CREATE_ROOM = 'CREATE_ROOM';

export const DELETE_ROOM = 'DELETE_ROOM';

export const UPDATE_ROOM = 'UPDATE_ROOM';

export const GET_DESIGN_LIST = 'GET_DESIGN_LIST';

export const GET_LOCATION = 'GET_LOCATION';

export const GET_LOCATION_LIST = 'GET_LOCATION_LIST';

export const CREATE_LOCATION = 'CREATE_LOCATION';

export const DELETE_LOCATION = 'DELETE_LOCATION';

export const UPDATE_LOCATION = 'UPDATE_LOCATION';

/**
 * THUNKS
 */

export const resetExpoList = () => dispatch => dispatch(createAction(RESET_EXPO_LIST));

export const resetRoomList = () => dispatch => dispatch(createAction(RESET_ROOM_LIST));

export const resetRoom = () => dispatch => dispatch(createAction(RESET_ROOM));

export const getRoomList = (data = {}) => dispatch => {
  return dispatch(
    createAction(
      GET_ROOM_LIST,
      ApiService.getRoomList({
        ...data,
        type: 0,
      }),
      {
        __PARSABLE_ERROR: true,
      }
    )
  );
};

export const getExpoList = (data = {}) => dispatch => {
  return dispatch(
    createAction(
      GET_EXPO_LIST,
      ApiService.getRoomList({
        ...data,
        type: 1,
      }),
      {
        __PARSABLE_ERROR: true,
      }
    )
  );
};

export const getRoom = (id, query) => dispatch => {
  return dispatch(
    createAction(GET_ROOM, ApiService.getRoom(id, query), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const updateRoom = (id, query) => dispatch => {
  return dispatch(
    createAction(UPDATE_ROOM_DATA, ApiService.getRoom(id, query), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const createRoom = data => dispatch => {
  return dispatch(
    createAction(CREATE_ROOM, ApiService.createRoom(data), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const deleteRoom = id => dispatch => {
  return dispatch(
    createAction(DELETE_ROOM, ApiService.deleteRoom(id), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const addMembersToRoom = (id, data) => dispatch => {
  return dispatch(
    createAction(UPDATE_ROOM, ApiService.addMembersToRoom(id, data), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const getDesignList = query => dispatch => {
  return dispatch(
    createAction(GET_DESIGN_LIST, ApiService.getDesignList(query), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const getLocationList = query => dispatch => {
  return dispatch(
    createAction(GET_LOCATION_LIST, ApiService.getLocationList(query), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const createLocation = (id, data) => dispatch => {
  return dispatch(
    createAction(CREATE_LOCATION, ApiService.createLocation(id, data), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const updateLocation = (data) => dispatch => {
  return dispatch(
    createAction(UPDATE_LOCATION, ApiService.updateLocation(data), {
      __PARSABLE_ERROR: true,
    })
  );
};

export const deleteLocation = data => dispatch => {
  return dispatch(
    createAction(DELETE_LOCATION, ApiService.deleteLocation(data), {
      __PARSABLE_ERROR: true,
    })
  );
};
