import React, { useEffect, useState } from "react";
import BigLoader from "../../components/loader/bigLoader";
import Conf from "./conf/Conf";
import ConferenceManager from "../../managers/Conference/ConferenceManager";
import { ConferencesDataContextProvider } from "../../context/ConferancesDataContext";
import { UseGlobalConfigDataState } from "../../context/GlobalConfigDataContext";
import { Redirect } from "react-router";

const Conferences = () => {
  const { globalConfigDataState } = UseGlobalConfigDataState();
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
    publicId: "",
    min: "",
    date: "",
    isOpenModal: false,
    isLoaded: false
  });

  const handleModalState = () => {
    setState({ ...state, isOpenModal: !state.isOpenModal });
  };

  const getConferenceData = async () => {
    try {
      const response = await ConferenceManager.getConferenceByExpoId(globalConfigDataState.expoId);
      if (response.data.data.length) {
        setState({
          ...state,
          startDate: response.data.data[0].start_date,
          endDate: response.data.data[0].end_date,
          publicId: response.data.data[0].public_id,
          isLoaded: true
        });
      } else {
        return <Redirect to={'/404'}/>
      }

    } catch (e) {
      console.error("getConferenceByExpoId whit error");
    }
  };

  useEffect(() => {
    (async function useEffectIIFE() {
      if (globalConfigDataState.expoId) {
        await getConferenceData();
      }
    })();
  }, [globalConfigDataState.expoId]);

  return (
    <ConferencesDataContextProvider>
      {state.isLoaded ?
        <div className="conf">
          {state.isOpenModal &&
          <div className="status-b-holder">
            <div className="following-cont">
              <div onClick={handleModalState} className="following-cont-close">
              </div>
              <p className="following-cont-txt" style={{ color: "red" }}>
                The following files have not been attached
              </p>
              <div className="following-text-block">
                <div className="scroll-contain">
                  <div className="folloing-information-inner">
                    <p>The uploaded media is bigger than required.</p>
                    <p>The maximum sizes should be:</p>
                    <p>PDF,Photo,Video accepted</p>
                    <p>Max 3 files from each type.</p>
                    <p> Limitations:</p>
                    <p>+ Video 100mb</p>
                    <p>+ PDF 100mb</p>
                    <p>+ Photo 5mb</p>
                  </div>
                </div>
              </div>
              <div className="following-button">
                <button onClick={handleModalState} className="following-btn">CLOSE</button>
              </div>
            </div>
          </div>}
          <div className="conf-container">
            <Conf
              id={state.publicId}
              startDate={state.startDate}
              endDate={state.endDate}
              handleModalState={handleModalState}
            />
          </div>
        </div>
        : ""
      }
      <BigLoader isLoaded={state.isLoaded} />
    </ConferencesDataContextProvider>
  );
};

export default Conferences;
