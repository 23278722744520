import React, {Component} from 'react';
import Croppie from 'croppie';

import ErrorBoundary from '../../components/error-boundary';
import Translate from '../../components/translate';
import closeIcon from '../../assets/images/close_icon_popup.svg'

class CroppieContainer extends Component {
  state = {
    data: {},
  };

  croppieCont = React.createRef();

  componentDidMount() {
    const croppieOptions = {
      showZoomer: true,
      enableOrientation: true,
      mouseWheelZoom: 'ctrl',
      viewport: {
        width: this.props.data.width || 300,
        height: this.props.data.height || 200,
        type: 'square',
      },
      boundary: {
        width: this.props.data.width || 300,
        height: this.props.data.height || 200,
      },
    };

    this._croppie = new Croppie(this.croppieCont.current, croppieOptions);

    const reader = new FileReader();
    const file = this.props.data.file;
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this._croppie.bind({ url: reader.result });
      };
      reader.onerror = function (error) {
      };
    }
  }

  componentWillUnmount() {
    if (this._croppie) {
      this._croppie.destroy();
    }
  }

  render() {
    const {
      data: { width, height },
    } = this.props;

    return (
      <ErrorBoundary>
        <div className="croppie-container">
          <div className={"croppie-container_close"} onClick={this.props.closeModal}>
            <img src={closeIcon} alt=""/>
          </div>
          <h2 className="croppie-container-title">
            <Translate>Please crop your image to the exact size</Translate> ({width}x{height})px
          </h2>
          <div className="container--engine" ref={this.croppieCont}/>
          <div className="croppie-btns-row">
            <button type="button" className="croppie-btn" onClick={this.handleSave}>
              <Translate>SAVE</Translate>
            </button>
          </div>
        </div>
      </ErrorBoundary>
    );
  }

  handleSave = () => {
    this._croppie
      .result({
        type: 'blob',
        size: { width: this.props.data.width, height: this.props.data.height },
      })
      .then(blob => {
        const nameFragments = this.props.data.file.name.split('.');
        nameFragments[nameFragments.length - 1] = 'png';

        const file = new File([blob], nameFragments.join('.'), { type: 'image/png' });

        this.props.onSave(file);
      });
  };
}

export default CroppieContainer;
