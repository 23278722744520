export const ADD_IMAGES = 'ADD_IMAGES';
export const ADD_VIDEOS = 'ADD_VIDEOS';
export const OPEN_CLOSE_FILES_POPUP = 'OPEN_CLOSE_FILES_POPUP';
export const DRAGGING_FILE_ID = 'DRAGGING_FILE_ID';
export const PRODUCT_NAME = 'PRODUCT_NAME';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const SET_HALL_INDEX = 'SET_HALL_INDEX';
export const SET_ZONE_INDEX = 'SET_ZONE_INDEX';
export const ADD_PRODUCT_FOR_SUBMIT = 'ADD_PRODUCT_FOR_SUBMIT';
export const SORT_PURCHASE = 'SORT_PURCHASE';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const SET_VIDEO_THUMBNAILS = 'SET_VIDEO_THUMBNAILS';
export const SET_IMAGE_TO_CROPPIE = 'SET_IMAGE_TO_CROPPIE';
export const CLOSE_CROPPIE = 'CLOSE_CROPPIE';
export const CHANGE_CROPPIE_SIZES = 'CHANGE_CROPPIE_SIZES';
export const UPDATE_RESERVING_PRODUCT_LIST = 'UPDATE_RESERVING_PRODUCT_LIST';

export function addImages(images) {
  return {
    type: ADD_IMAGES,
    images
  }
}

export function addVideos(videos) {
  return {
    type: ADD_VIDEOS,
    videos
  }
}

export function openFilesPopup(open, id) {
  return {
    type: OPEN_CLOSE_FILES_POPUP,
    open,
    id
  }
}

export function setDraggingFileID(id) {
  return {
    type: DRAGGING_FILE_ID,
    id
  }
}

export function setProductName(name) {
  return {
    type: PRODUCT_NAME,
    name
  }
}

export function addProduct(product) {
  return {
    type: ADD_PRODUCT,
    product
  }
}

export function setHallIndex(index) {
  return {
    type: SET_HALL_INDEX,
    index
  }
}

export function setZoneIndex(index) {
  return {
    type: SET_ZONE_INDEX,
    index
  }
}

export function addProductForSubmit(product) {
  return {
    type: ADD_PRODUCT_FOR_SUBMIT,
    product
  }
}

export function updateProduct(media, publicId) {
  return {
    type: UPDATE_PRODUCT,
    media,
    publicId
  }
}

export function sortPurchase(purchases, firstPurchase) {
  return {
    type: SORT_PURCHASE,
    purchases,
    firstPurchase
  }
}

export function setVideoThumbnails(thumbnails) {
  return {
    type: SET_VIDEO_THUMBNAILS,
    thumbnails
  }
}

export function setImageToCroppie(media, productIndex) {
  return {
    type: SET_IMAGE_TO_CROPPIE,
    media,
    productIndex
  }
}

export function closeCroppie() {
  return {
    type: CLOSE_CROPPIE,
  }
}

export function changeCroppieSizes(sizes) {
  return {
    type: CLOSE_CROPPIE,
    sizes
  }
}

export function updateReservingProductList(products) {
  return {
    type: UPDATE_RESERVING_PRODUCT_LIST,
    products
  }
}
