import React from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from '../error-boundary';

import { Modal, ModalBody } from 'reactstrap';

import closeIcon from '../../assets/images/close_icon.png';

const MessageModal = ({ data, ...props }) => {
  return (
    <ErrorBoundary>
      <Modal className="app-modal bg-transparent" {...props}>
        <ModalBody className="p-0 bg-transparent position-relative">
          <div className="app-modal__content">
            <div className="row">
              <div className="col col-12 py-3">
                <p className="text-center mb-0">{data.message}</p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </ErrorBoundary>
  );
};

MessageModal.defaultProps = {};

MessageModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default MessageModal;
