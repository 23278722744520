import React, { useEffect, useState } from "react";
import userIcon from "../../../../assets/images/userIcon.png";
import ConferenceManager from "../../../../managers/Conference/ConferenceManager";
import Spinner from "../../../../components/loader/spinner";
import profilePicIcon from "../../../../assets/images/profilePicIcon.svg";

const UserInfo = props => {

  const [userInfo, setUserInfo] = useState({
    isLoaded: false,
    data: {}
  });

  const getSpeakerInfo = async () => {
    try {
      const response = await ConferenceManager.getSpeakersData(props.selectedConference.publicId);
      setUserInfo({ ...userInfo, isLoaded: true, data: response.data.data });
    } catch (e) {

    }
  };

  useEffect(() => {
    (async function useEffectIIFE() {
      if (props.selectedConference.publicId) {
        await getSpeakerInfo();
      }
    })();
  }, [props.selectedConference.publicId]);

  return (
    userInfo.isLoaded ?
      <div className="conference-info">
        <div className="conference-info_subject">
          <p>Subject</p>
          <div className="conference-info_subject_value">
            {userInfo.data.subject}
          </div>
        </div>
        <div className="conference-info_description">
          <p>Short Description</p>
          <div className="conference-info_description_value">
            {userInfo.data.description}
          </div>
        </div>
        <div className="conference-info_speakers">
          <p>Speakers</p>
          <div className="conference-info_speakers_value speakers-box">
            {userInfo.data.speakers?.length ?
              userInfo.data.speakers.map((speaker, index) =>
                <div className="speakers-box__speaker" key={index}
                     style={{ width: 100 / userInfo.data.speakers.length + "%" }}
                >
                  <div className="speakers-box__speaker_avatar"
                       style={{ backgroundImage: `url(${!!speaker.user_data.avatar ? speaker.user_data.avatar : profilePicIcon})` }}>
                  </div>
                  <p className="speakers-box__speaker_name">{speaker.user_data.name}</p>
                  <p
                    className="speakers-box__speaker_position">{speaker.user_data.position} of {speaker.user_data.office}</p>
                </div>
              )
              :
              <div className="info-user-profile-photo">
                No Speaker
              </div>
            }
          </div>
        </div>
      </div>
      :
      <Spinner />
  );
};

export default UserInfo;
