import React, {useEffect} from 'react';
import {Carousel} from "react-responsive-carousel";
import Branding from "./branding";
import Runner from "./runner";
import Logo from "./logo";
import Monitor from "./monitor";
import {useDispatch, useSelector} from "react-redux";
import {setZoneIndex} from "../../../store/actions/products";

const CustomMap = ({data, selectedId, colorIndex, className, ...props}) => {

  const selectedZoneIndex = useSelector(state => state.products.zoneIndex)
  const productName = useSelector(state => state.products.selectedProductName)
  const dispatch = useDispatch()

  const handleSelect = monitor => {
    if (!monitor.is_reserved) {
      props.onSelect(monitor.public_id);
    }
  };

  const changeZoneIndex = (index) => {
    dispatch(setZoneIndex(index))
  }

  useEffect(() => {
    changeZoneIndex(0)
  }, [colorIndex])

  return (
    <>
      <Carousel
        axis={"horizontal"}
        infiniteLoop={false}
        showStatus={false}
        showIndicators={false}
        selectedItem={selectedZoneIndex}
        onChange={e => changeZoneIndex(e)}
      >
        {data.zones.map((zone, index) => (
            <div key={index} className={`map-zone map-zone_${colorIndex}`}>
              <div className={"map-zone__title"}>Zone {index + 1}</div>
              <div className={`map-zone_main`}>
                {productName === 'branding' ?
                  <Branding
                    branding={zone.branding}
                  />
                  : productName === 'runner' ?
                    <Runner
                      runner={zone.runner}
                    />
                    : productName === 'logo' ?
                      <Logo
                        logo={zone.logo}
                      />
                      : productName === 'monitor' ?
                        <Monitor
                          monitors={zone.monitor}
                        /> : ''
                }
              </div>
            </div>
          )
        )}
      </Carousel>
      <div className={"carousel-indicator"}>
        {selectedZoneIndex+1}/{data.zones.length}
      </div>
    </>
  );
};

export default CustomMap;
