import React from "react";
import FloorPlan from "../../../assets/images/floor-plan.png";
import {useDispatch, useSelector} from "react-redux";
import DragAndDrop from "../dragAndDrop";
import {openFilesPopup} from "../../../store/actions/products";

const Branding = (props) => {

  const dispatch = useDispatch()
  const readyForSubmit = useSelector(state => state.products.readyForSubmit.branding)

  const brandingItem = (i) => {

    const isReadyForSubmit = readyForSubmit.find(item => item.product_schema_id === props.branding[i].public_id)

    return (
      <DragAndDrop
        key={props.branding[i].public_id}
        class={`map-zone-b-inner ${props.branding[i].is_reserved ? 'reserved' : ''}`}
        onClick={() => dispatch(openFilesPopup(true, props.branding[i].index))}
        index={props.branding[i].index}
      >
        {isReadyForSubmit ? <img src={URL.createObjectURL(isReadyForSubmit.product_photo)} alt=""/> : props.branding[i].index}
      </DragAndDrop>
    )
  }

  return (
    <>
      <div className="map-zone-branding">
        <div className="map-zone-b-top">
          {[0, 1, 2, 3, 4, 5].map(i => brandingItem(i))}
        </div>
        <div className="map-zone-b-right">
          <div className="map-zone-b-right-inner">
            {[6, 7].map(i => brandingItem(i))}
          </div>
          <div className="map-zone-b-right-inner">
            {[8, 9].map(i => brandingItem(i))}
          </div>
        </div>
        <div className="map-zone-b-left">
          <div className="map-zone-b-right-inner">
            {[19, 18].map(i => brandingItem(i))}
          </div>
          <div className="map-zone-b-right-inner">
            {[17, 16].map(i => brandingItem(i))}
          </div>
        </div>
        <div className="map-zone-b-bottom">
          {[15, 14, 13, 12, 11, 10].map(i => brandingItem(i))}
        </div>
      </div>
      <img src={FloorPlan} alt=""/>
    </>
  )
}

export default Branding
