import axios from 'axios';
import React from "react";

const sendHTTPRequest = (requestProps) => {
  switch (requestProps.method) {
    case 'post':
      return axios.post(process.env.REACT_APP_API_URL + requestProps.url, requestProps.body, requestProps.config);
    case 'put':
      return axios.put(process.env.REACT_APP_API_URL + requestProps.url, requestProps.body, requestProps.config);
    case 'get':
      return axios.get(process.env.REACT_APP_API_URL + requestProps.url, requestProps.config);
    case 'delete':
      return axios.delete(process.env.REACT_APP_API_URL + requestProps.url, requestProps.config);
  }
}

const axiosWrapper = async (requestProps) => {
  const defaultHeaders = {
    'Authorization': `Token ${localStorage.getItem('token')}`,
    'Access-Control-Allow-Origin': '*',
  };
  const config = {
    headers: {...defaultHeaders, ...requestProps?.config?.headers},
  };

  const response = sendHTTPRequest({method: requestProps.method, body: requestProps.body, url: requestProps.url, config});
  response.catch(async (error) => {

  })
  return response;
};

export default axiosWrapper;
