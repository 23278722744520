import * as actionTypes from '../actions/conf';

let _ = require('lodash')

const initialState = {
    getConf: {
        loaded: null,
        error: null,
        data: null,
    },
    duration: {
        loaded: null,
        error: null,
        data: null,
    },
    speaker: {
        loaded: null,
        error: null,
        data: {},
    }
};

export default (state = initialState, action) => {
    switch (action.type) {

        /* RESET PART */
        case `${actionTypes.RESET_CONF_DATE}`: {
            return {
                ...state,
                date: {
                    ...state.date,
                    // data: null,
                    // loaded: true,
                    error: null,
                },
            };
        }

        case `${actionTypes.RESET_CONF_HOURS}`: {
            return {
                ...state,
                hours: {
                    data: [],
                    loaded: true,
                    error: null,
                },
            };
        }

        case `${actionTypes.RESET_CONF}`: {
            return {
                ...state,
                conf: {
                    data: [],
                    loaded: true,
                    error: null,
                },
            };
        }

        /* GET DATA AFTER PASSING DATE */
        case `${actionTypes.GET_CONF}_LOADING`: {
            return {
                ...state,
                getConf: {
                    ...state.getConf,
                    loaded: false,
                    error: null,
                },
            };
        }

        case `${actionTypes.GET_CONF}_SUCCESS`: {
            return {
                ...state,
                getConf: {
                    ...state.getConf,
                    loaded: true,
                    error: null,
                    data: action.payload.data,
                },
            };
        }

        case `${actionTypes.GET_CONF}_ERROR`: {
            return {
                ...state,
                getConf: {
                    ...state.getConf,
                    loaded: true,
                    error: action.payload,
                },
            };
        }

        /*  GET DATA AFTER PASSING HOURS  */
        case `${actionTypes.CONF_DURATION}_LOADING`: {
            return {
                ...state,
                duration: {
                    ...state.duration,
                    loaded: false,
                    error: null,
                },
            };
        }
        case `${actionTypes.CONF_DURATION}_SUCCESS`: {
            return {
                ...state,
                duration: {
                    ...state.duration,
                    loaded: true,
                    error: null,
                    data: action.payload,
                },
            };
        }
        case `${actionTypes.CONF_DURATION}_ERROR`: {
            return {
                ...state,
                duration: {
                    ...state.duration,
                    loaded: true,
                    error: action.payload,
                },
            };
        }
        /*  RESERVE YOUR PLACE IN CONFERENCE HALL  */
        case `${actionTypes.GET_SPEAKER}_LOADING`: {
            return {
                ...state,
                speaker: {
                    ...state.speaker,
                    loaded: false,
                    error: null,
                },
            };
        }
        case `${actionTypes.GET_SPEAKER}_SUCCESS`: {
            return {
                ...state,
                speaker: {
                    ...state.speaker,
                    loaded: true,
                    error: null,
                    data: _.isEmpty(action.payload.data) ? {} : action.payload.data,
                },
            };
        }
        case `${actionTypes.GET_SPEAKER}_ERROR`: {
            return {
                ...state,
                speaker: {
                    ...state.speaker,
                    loaded: true,
                    error: action.payload,
                },
            };
        }
        case `${actionTypes.PUT_SPEAKER}`: {
            return {
                ...state,
                speaker: {
                    ...state.speaker,
                    loaded: true,
                    error: null,
                    data: initialState.speaker.data
                },
            };
        }
        default:
            return state;
    }
};
