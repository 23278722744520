import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import photoIcon from "../../assets/images/photo-icon.svg";
import pdfIcon from "../../assets/images/pdf-icon.svg";
import vdIcon from "../../assets/images/video-icon.svg";
import XButton from "../../assets/images/clear.png";
import arrowGray from "../../assets/images/arrow-gray.svg";
import swipe from "../../assets/images/swipe.svg";
import uploadIcon from "../../assets/images/upload-icon.svg";
import { ValidationHelper } from "../../helpers/ValidationHelper";
import AuthManager from "../../managers/AuthManager";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Dropzone = props => {

  const [progress, setProgress] = useState(0);
  const [rows, setRows] = useState([]);
  const [hideFilesIndexes, setHideFilesIndexes] = useState([]);
  let order = 0;

  const onDrop = async (acceptedFiles, index) => {
    const formData = new FormData();
    let rowIndex = typeof index === "number" ? index : rows.length - 1;
    const newRows = [...rows];
    const row = newRows[rowIndex];

    const createdFiles = {
      videos: [],
      photos: [],
      presentations: []
    };

    const createdFilesErrors = { ...row.uploadErrors };

    Array.from(acceptedFiles).forEach(file => {

      if (file.type.indexOf("image") > -1) {
        if (
          (ValidationHelper.ImageValidation(file) && typeof index !== "number") ||
          (ValidationHelper.ImageValidation(file) && typeof index === "number" && newRows[rowIndex].uploaded.photos.length + createdFiles.photos.length < 3)
        ) {
          formData.append("file", file);
          createdFiles.photos.push(file);
        } else {
          createdFilesErrors.photos.push(file);
        }
      } else if (file.type.indexOf("pdf") > -1) {
        if (
          (ValidationHelper.PDFValidation(file) && typeof index !== "number") ||
          (ValidationHelper.PDFValidation(file) && typeof index === "number" && newRows[rowIndex].uploaded.presentations.length + createdFiles.presentations.length < 3)
        ) {
          formData.append("file", file);
          createdFiles.presentations.push(file);
        } else {
          createdFilesErrors.presentations.push(file);
        }
      } else if (file.type.indexOf("video") > -1) {
        if (
          (ValidationHelper.VideoValidation(file) && typeof index !== "number") ||
          (ValidationHelper.VideoValidation(file) && typeof index === "number" && newRows[rowIndex].uploaded.videos.length + createdFiles.videos.length < 3)
        ) {
          formData.append("file", file);
          createdFiles.videos.push(file);
        } else {
          createdFilesErrors.videos.push(file);
        }
      }
    });

    newRows[rowIndex] = {
      ...row,
      addedToUpload: {
        videos: [...createdFiles.videos, ...row.addedToUpload.videos],
        photos: [...createdFiles.photos, ...row.addedToUpload.photos],
        presentations: [...createdFiles.presentations, ...row.addedToUpload.presentations]
      },
      uploadErrors: createdFilesErrors
    };
    setRows(newRows);

    try {

      const response = await AuthManager.uploadFile(formData, setProgress);
      const addedToUploadFiles = { ...row.addedToUpload };

      const newRowsAfterUpload = [...rows];

      response.data.data.files.forEach(file => {
        if (file.path.indexOf("image") > 0) {
          let oldFile = createdFiles.photos.find(item => item.name === file.original_file_name);
          let index = addedToUploadFiles.photos.indexOf(file);
          newRowsAfterUpload[rowIndex].uploaded.photos.push({ name: oldFile.name, response: file });
          newRowsAfterUpload[rowIndex].addedToUpload.photos.splice(index, 1);
        } else if (file.path.indexOf("video") > 0) {
          let oldFile = createdFiles.videos.find(item => item.name === file.original_file_name);
          let index = addedToUploadFiles.videos.indexOf(file);
          newRowsAfterUpload[rowIndex].uploaded.videos.push({ name: oldFile.name, response: file });
          newRowsAfterUpload[rowIndex].addedToUpload.videos.splice(index, 1);
        } else if (file.path.indexOf("pdf") > 0) {
          let oldFile = createdFiles.presentations.find(item => item.name === file.original_file_name);
          let index = addedToUploadFiles.presentations.indexOf(file);
          newRowsAfterUpload[rowIndex].uploaded.presentations.push({ name: oldFile.name, response: file });
          newRowsAfterUpload[rowIndex].addedToUpload.presentations.splice(index, 1);
        }
      });

      setRows(newRowsAfterUpload);
    } catch (e) {

    }
    setProgress(0);
  };

  const onDragEnd = (result) => {

    if (!result.destination) {
      return;
    }
    const newRows = [...rows];
    const processId = result.draggableId.slice(1, 2);
    const process = processId === "1" ? "uploaded" : processId === "2" ? "addedToUpload" : "uploadErrors";
    const fileTypeId = result.draggableId.slice(0, 1);
    const fileType = fileTypeId === "1" ? "photos" : fileTypeId === "2" ? "presentations" : "videos";
    const fileIndex = result.draggableId.slice(3, 4);

    if (+result.destination.droppableId !== rows.length - 1 && processId === "1" && newRows[result.destination.droppableId][process][fileType].length >= 3) {
      return;
    }
    const [removed] = rows[result.source.droppableId][process][fileType].splice(fileIndex, 1);
    newRows[result.destination.droppableId][process][fileType].splice(0, 0, removed);
    setRows(newRows);
  };

  const handleFile = (file, fileType, process, itemIndex, rowIndex) => {

    let options = {
      fileIcon: fileType === 1 ? photoIcon : fileType === 3 ? vdIcon : pdfIcon,
      processColor: process === 1 ? "#27AE60" : process === 2 ? "#2196f3" : "red"
    };

    const removeFile = () => {

      const type = fileType === 1 ? "photos" : fileType === 2 ? "presentations" : "videos";
      const rowsCopy = [...rows];
      if (process === 3) {
        const errorsCopy = { ...rows[rowIndex].uploadErrors };
        errorsCopy[type].splice(itemIndex, 1);
        rowsCopy[rowIndex].uploadErrors = errorsCopy;
      } else if (process === 1) {
        const uploadedCopy = { ...rows[rowIndex].uploaded };

        uploadedCopy[type].splice(itemIndex, 1);
        rowsCopy[rowIndex].uploaded = uploadedCopy;
      } else {
        const addedCopy = { ...rows[rowIndex].addedToUpload };
        addedCopy[type].splice(itemIndex, 1);
        rowsCopy[rowIndex].addedToUpload = addedCopy;
      }

      setRows(rowsCopy);
    };

    return (
      <Draggable
        key={`${fileType}${process}${rowIndex}${itemIndex}`}
        draggableId={`${fileType}${process}${rowIndex}${itemIndex}`}
        index={order++}
        isDragDisabled={!!progress}
      >
        {(provided, snapshot) => {
          return (
            <div className="file"
                 ref={provided.innerRef}
                 {...provided.draggableProps}
                 {...provided.dragHandleProps}
                 style={{
                   userSelect: "none",
                   ...provided.draggableProps.style
                 }}
            >
              <div className={"file_swipe"}>
                <img src={swipe} alt="" />
              </div>
              <div className="file_icon">
                <img src={options.fileIcon} alt={""} />
              </div>
              <div className="file_name">
                <p>{file.name}</p>
                <p className={`success ${process === 1 ? "hide" : ""}`}
                   style={{ color: options.processColor }}>
                  {process === 2 ? `Uploading... ${progress + "%"}` : "Uploading error"}
                </p>
                {process === 2 ? <div className="green" style={{
                  width: process === 2 ? progress + "%" : "100%",
                  backgroundColor: options.processColor
                }}
                /> : ""}
              </div>
              {process !== "added" ?
                <div className="file_delete-icon" onClick={e => removeFile()}>
                  <img src={XButton} alt="" />
                </div>
                : ""
              }
            </div>
          );
        }}
      </Draggable>
    );
  };

  const hideFiles = (index) => {
    let newIndexes = [...hideFilesIndexes];
    let elIndex = newIndexes.indexOf(index);
    if (elIndex < 0) {
      newIndexes.push(index);
      setHideFilesIndexes(newIndexes);
    } else {
      newIndexes.splice(elIndex, 1)
      setHideFilesIndexes(newIndexes);
    }
  };

  const handleRows = (row, index) => {

    const isLoopAfter = index === rows.length - 1;

    return (
      <div
        className={`${isLoopAfter ? "files__other-files" : ""} files__assigned-files ${index === props.selectedSpeaker ? "selected" : ""} ${!isLoopAfter && hideFilesIndexes.indexOf(index) >= 0 ? "hide-files" : ""}`}
        key={row.uuid}
      >
        <input type="file" id={`upload-by-speaker-${index}`} multiple
               onChange={(e) => onDrop(e.target.files, index)}
               disabled={!!progress}
               accept={".jpg, .jpeg, .png, .pdf, .mp4, .mkv, .avi, .mov, .flv"}
        />
        <div className={"files__assigned-files_header"}>
          <div className={"assigned_header_name"}>
            {props.speakers[index]?.name ? props.speakers[index].name : row.name}
          </div>
          {!isLoopAfter ?
            <>
              <label htmlFor={`upload-by-speaker-${index}`} className={"assigned_header_upload"}>
                <img src={uploadIcon} alt="" />
              </label>
              <div className={"assigned_header_count"} onClick={() => !isLoopAfter ? hideFiles(index) : ""}>
                <span>{row.uploaded.videos.length + row.uploaded.presentations.length + row.uploaded.photos.length}/9</span>
                <span><img src={arrowGray} alt="" /></span>
              </div>
            </> : ""
          }
        </div>
        <Droppable droppableId={`${index}`} key={row.uuid}>
          {(provided, snapshot) => {
            return (
              <div className={`files__assigned-files_box`}
                   {...provided.droppableProps}
                   ref={provided.innerRef}
              >
                {row.uploaded.photos.map((item, itemIndex) => handleFile(item, 1, 1, itemIndex, index))}
                {row.uploaded.presentations.map((item, itemIndex) => handleFile(item, 2, 1, itemIndex, index))}
                {row.uploaded.videos.map((item, itemIndex) => handleFile(item, 3, 1, itemIndex, index))}
                {row.addedToUpload.photos.map((item, itemIndex) => handleFile(item, 1, 2, itemIndex, index))}
                {row.addedToUpload.presentations.map((item, itemIndex) => handleFile(item, 2, 2, itemIndex, index))}
                {row.addedToUpload.videos.map((item, itemIndex) => handleFile(item, 3, 2, itemIndex, index))}
                {row.uploadErrors.photos.map((item, itemIndex) => handleFile(item, 1, 3, itemIndex, index))}
                {row.uploadErrors.presentations.map((item, itemIndex) => handleFile(item, 2, 3, itemIndex, index))}
                {row.uploadErrors.videos.map((item, itemIndex) => handleFile(item, 3, 3, itemIndex, index))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>

      </div>
    );

  };

  const submit = (e) => {
    e.preventDefault();
    props.handleSubmit(rows);

  };

  const dropZoneClick = e => {
    const classes = ["choose-file-label", "drop-empty"];
    if (classes.indexOf(e.target.className) < 0) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const newRows = [];
    props.speakers.map(() => {
      newRows.push({
        uuid: uuidv4(),
        name: "Speaker",
        addedToUpload: {
          videos: [],
          photos: [],
          presentations: []
        },
        uploaded: {
          videos: [],
          photos: [],
          presentations: []
        },
        uploadErrors: {
          videos: [],
          photos: [],
          presentations: []
        }
      });
    });

    newRows.push({
      uuid: uuidv4(),
      name: "Other files",
      addedToUpload: {
        videos: [],
        photos: [],
        presentations: []
      },
      uploaded: {
        videos: [],
        photos: [],
        presentations: []
      },
      uploadErrors: {
        videos: [],
        photos: [],
        presentations: []
      }
    });
    setRows(newRows);
  }, []);

  useEffect(() => {
    if (props.addSpeakerStatus) {
      const newRows = [...rows];
      newRows.splice(props.speakers.length - 1, 0, {
        uuid: uuidv4(),
        name: "Speaker",
        addedToUpload: {
          videos: [],
          photos: [],
          presentations: []
        },
        uploaded: {
          videos: [],
          photos: [],
          presentations: []
        },
        uploadErrors: {
          videos: [],
          photos: [],
          presentations: []
        }
      });
      setRows(newRows);
      props.setAddSpeakerStatus(false);
    }
  }, [props.addSpeakerStatus]);

  useEffect(() => {
    if (props.removedSpeakerId >= 0) {
      const newRows = [...rows];
      newRows.splice(props.removedSpeakerId, 1);
      setRows(newRows);
      props.setRemovedSpeakerId(-1);
    }

  }, [props.removedSpeakerId]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: !!progress });

  return (
    <>
      <div className="drop-zone">
        <div className="drop-zone-cont">
          <div className={"drop-zone-cont__files"}>
            <DragDropContext
              onDragEnd={result => onDragEnd(result)}
            >
              {rows.map((row, index) => handleRows(row, index))}
            </DragDropContext>
          </div>
        </div>
        <div className={"drop-zone__drag"} {...getRootProps({ onClick: e => dropZoneClick(e) })}>
          {isDragActive ? "Drag Active" : ""}
          <h5><span>Drag & Drop</span> your files (PDF, photo, video) here</h5>
          <label htmlFor="choose-file" id="choose-file-label" className={"choose-file-label"}>
            browse
          </label>
          <input {...getInputProps()} disabled={!!progress}
                 accept={".jpg, .jpeg, .png, .pdf, .mp4, .mkv, .avi, .mov, .flv"} />
        </div>
      </div>
      <input onClick={e => submit(e)} className={`submit_button ${props.isFormValid ? "valid" : ""}`} type="submit"
             value={props.isFormValid ? "Submit" : "Fill the fields"} />
    </>
  );
};

export default Dropzone;
