import createAction from './create-action';
import ApiService from '../../services/api-service';

export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';

export const getSearchList = (id, query) => dispatch => {
    return dispatch(
        createAction(GET_SEARCH_LIST, ApiService.getSearchList(id, query), {
            __PARSABLE_ERROR: true,
        })
    );
};
