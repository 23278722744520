import React from 'react';
import loader from '../../assets/images/loadingGif.svg'

const Spinner = () => {
  return (
    <div className="custLoad">
      <img src={loader} />
    </div>
  );
};

export default Spinner;
