import React from "react";
import FloorPlan from "../../../assets/images/floor-plan.png";
import {openFilesPopup} from "../../../store/actions/products";
import {useDispatch, useSelector} from "react-redux";
import DragAndDrop from "../dragAndDrop";

const Logo = (props) => {

  const dispatch = useDispatch()
  const readyForSubmit = useSelector(state => state.products.readyForSubmit.logo)
  const isReadyForSubmit = readyForSubmit.find(item => item.product_schema_id === props.logo[0].public_id)
  const styles = {}
  if (isReadyForSubmit) {
    styles.backgroundImage = `url(${URL.createObjectURL(isReadyForSubmit.product_photo)})`
  }

  return (
    <>
      <div className="map-zone-logo">
        <DragAndDrop
          class={`map-zone-l-inner  ${props.logo[0].is_reserved ? 'reserved' : ''}`}
          onClick={() => dispatch(openFilesPopup(true, props.logo[0].index))}
          index={1}
        >
          {isReadyForSubmit ? <img src={URL.createObjectURL(isReadyForSubmit.product_photo)} alt=""/> :
            <span>{props.logo[0].is_reserved ? 'Reserved' : 'Logo'}</span>
          }
        </DragAndDrop>
      </div>
      <img src={FloorPlan} alt=""/>
    </>
  )
}

export default Logo
