import * as actionTypes from '../actions/user';

const initialState = {
  current: {
    loaded: true,
    error: null,
    data: null,
  },
  list: {
    loaded: true,
    error: null,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    /*  GET USER PART  */

    default:
      return state;
  }
};
