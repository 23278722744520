import React, {useEffect, useState} from "react";
import profilePicCreateIcon from "../../../../assets/images/profilePicCreateIcon.svg";
import addSpeakerIcon from "../../../../assets/images/addSpeakerIcon.svg";
import closeIcon from "../../../../assets/images/clear.png";
import checkmarkCircle from "../../../../assets/images/checkmark-circle.png";
import DropZone from "../../../../components/dropZone/Dropzone";
import ConferenceManager from "../../../../managers/Conference/ConferenceManager";
import Spinner from "../../../../components/loader/spinner";
import AuthManager from "../../../../managers/AuthManager";
import {ValidationHelper} from "../../../../helpers/ValidationHelper";
import {DateHelper} from "../../../../helpers/DateHelper";
import {Carousel} from "react-responsive-carousel";

const UserInfoCreate = props => {

  const initialSpeakerData = {
    avatar: "",
    presentations: [],
    videos: [],
    photos: [],
    name: "",
    title: "",
    phone: "",
    mail: "",
    company: ""
  };
  const initialSpeakerDataError = {
    avatar: false,
    name: false,
    title: false,
    phone: false,
    mail: false,
    company: false
  };
  const [removedSpeakerId, setRemovedSpeakerId] = useState(-1);
  const [addSpeakerStatus, setAddSpeakerStatus] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [userInfo, setUserInfo] = useState({
    isLoaded: false,
    isAvatarUploading: false,
    submitting: false,
    submitted: false,
    data: {}
  });
  const [submitFormData, setSubmitFormData] = useState({
    subject: "",
    description: "",
    speakers: [initialSpeakerData]
  });
  const [selectedSpeaker, setSelectedSpeaker] = useState(0);
  const [submitFormDataErrors, setSubmitFormDataErrors] = useState({
    subject: false,
    description: false,
    speakers: [initialSpeakerDataError]
  });


  const handleChange = (value, name) => {
    const newData = { ...submitFormData };
    newData.speakers[selectedSpeaker][name] = `${value}`;
    setSubmitFormData(newData);
    const newErrorData = { ...submitFormDataErrors };
    newErrorData.speakers[selectedSpeaker][name] = false;
    setSubmitFormDataErrors(newErrorData);
    setIsFormValid(handleValidation(false));
  };

  const handleImageChange = async e => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file && ValidationHelper.ImageValidation(file)) {
      setUserInfo({ ...userInfo, isAvatarUploading: true });
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await AuthManager.uploadFile(formData, () => {
        });
        const newData = { ...submitFormData };
        newData.speakers[selectedSpeaker].avatar = response.data.data.files[0].path;
        setSubmitFormData(newData);
      } catch (e) {
        console.log("Profile Pic not Uploaded");
      }
      setUserInfo({ ...userInfo, isAvatarUploading: false });
    } else {
      console.log("Profile Pic is Invalid");
    }
  };

  const handleValidation = (checkErrors) => {
    let errors = { ...submitFormDataErrors };
    let formIsValid = true;

    submitFormData.speakers.map((speaker, index) => {
      if (speaker.name === "") {
        formIsValid = false;
        if (checkErrors) {
          errors.speakers[index].name = true;
        }
      }

      if (speaker.title !== "") {
        errors.speakers[index].title = false;
        if (speaker.title.length > 100) {
          formIsValid = false;
          if (checkErrors) {
            errors.speakers[index].title = true;
          }
        }
      } else {
        formIsValid = false;
        if (checkErrors) {
          errors.speakers[index].title = true;
        }
      }

      if (speaker.company !== "") {
        errors.speakers[index].company = false;
        if (speaker.company.length > 100) {
          formIsValid = false;
          if (checkErrors) {
            errors.speakers[index].company = true;
          }
        }
      } else {
        formIsValid = false;
        if (checkErrors) {
          errors.speakers[index].company = true;
        }
      }

      if (speaker.mail !== "") {
        errors.speakers[index].mail = false;
        let mail = speaker.mail;
        if (
          !(mail.lastIndexOf("@") < mail.lastIndexOf(".")
            && mail.lastIndexOf("@") > 0
            && mail.indexOf("@@") === -1
            && mail.lastIndexOf(".") > 2
            && (mail.length - mail.lastIndexOf(".")) > 2)
        ) {
          formIsValid = false;
          if (checkErrors) {
            errors.speakers[index].mail = true;
          }
        }
      } else {
        formIsValid = false;
        if (checkErrors) {
          errors.speakers[index].mail = true;
        }
      }

      if (speaker.phone !== "") {
        errors.speakers[index].phone = false;
        if (speaker.phone.length < 5 && speaker.phone.length > 16) {
          formIsValid = false;
          if (checkErrors) {
            errors.speakers[index].phone = true;
          }
        }
      } else {
        formIsValid = false;
        if (checkErrors) {
          errors.speakers[index].phone = true;
        }
      }
    });

    errors.subject = false;
    if (submitFormData.subject.length < 10 || submitFormData.subject.length > 150) {
      formIsValid = false;
      if (checkErrors) {
        errors.subject = true;
      }
    }
    errors.description = false;
    if (submitFormData.description.length < 200 || submitFormData.description.length > 1000) {
      formIsValid = false;
      if (checkErrors) {
        errors.description = true;
      }
    }
    setSubmitFormDataErrors({...errors});
    return formIsValid;
  };

  const confirmSubmit = () => {
    window.location.reload(false);
  }

  const handleSubmit = async (rows) => {

    const speakers = submitFormData.speakers.map((speaker, index) => {
      return {
        presentations: rows[index].uploaded.presentations.map(presentation => presentation.response.path),
        videos: rows[index].uploaded.videos.map(video => video.response.path),
        photos: rows[index].uploaded.photos.map(photo => photo.response.path),
        user: {
          email: speaker.mail,
          username: speaker.mail,
          full_name: speaker.name,
          office: speaker.company,
          profile_pic: speaker.avatar,
          position: speaker.title,
          phone_number: "+" + speaker.phone
        }
      };
    });

    const submitUserInfo = {
      public_id: props.state.index,
      title: submitFormData.subject,
      conference_hall_id: props.hallId,
      description: submitFormData.description,
      start_date: DateHelper.dateFormatYYYYMMDD(props.state.calendarSelectedDate) + "T" + props.state.createConference.startDate + ":00.000Z",
      end_date: DateHelper.dateFormatYYYYMMDD(props.state.calendarSelectedDate) + "T" + props.state.createConference.endDate + ":00.000Z",
      speakers: speakers
    };

    if (handleValidation(true)) {
      setUserInfo({...userInfo, submitting: true});

      try {
        await ConferenceManager.submitConference(submitUserInfo);
        setUserInfo({
          ...userInfo,
          submitting: false,
          submitted: true
        })

      } catch (e) {
      }
    }
  };

  const getSpeakerInfo = async () => {
    try {
      const response = await ConferenceManager.getSpeakersData(props.state.index);
      setUserInfo({ ...userInfo, isLoaded: true, data: response.data.data });
    } catch (e) {

    }
  };

  const changeSpeakerData = (uploadedFiles) => {
    const speakerData = { ...submitFormData };
    speakerData.speakers[selectedSpeaker].photos = [];
    speakerData.speakers[selectedSpeaker].videos = [];
    speakerData.speakers[selectedSpeaker].presentations = [];
    uploadedFiles.photos.forEach(item => speakerData.speakers[selectedSpeaker].photos.push(item));
    uploadedFiles.videos.forEach(item => speakerData.speakers[selectedSpeaker].videos.push(item));
    uploadedFiles.presentations.forEach(item => speakerData.speakers[selectedSpeaker].presentations.push(item));
    setSubmitFormData(speakerData);
  };

  const addSpeaker = () => {
    const newSubmitFormDataErrors = { ...submitFormDataErrors };
    const newSubmitFormData = { ...submitFormData };
    newSubmitFormData.speakers.push(initialSpeakerData);
    newSubmitFormDataErrors.speakers.push(initialSpeakerDataError);
    setSubmitFormData(newSubmitFormData);
    setSubmitFormDataErrors(newSubmitFormDataErrors);
    setSelectedSpeaker(newSubmitFormData.speakers.length - 1);
    setAddSpeakerStatus(true);
  };

  const removeSpeaker = () => {
    let index = selectedSpeaker;
    if (submitFormData.speakers.length === selectedSpeaker + 1) {
      setSelectedSpeaker(selectedSpeaker - 1);
    }
    const newSubmitFormDataErrors = { ...submitFormDataErrors };
    const newSubmitFormData = { ...submitFormData };
    newSubmitFormData.speakers.splice(index, 1);
    newSubmitFormDataErrors.speakers.splice(index, 1);
    setSubmitFormData(newSubmitFormData);
    setSubmitFormDataErrors(newSubmitFormDataErrors);
    setRemovedSpeakerId(selectedSpeaker);
  };

  useEffect(() => {
    setUserInfo({ ...userInfo, isLoaded: props.state.free });
    if (!props.state.free) {
      (async function useEffectIIFE() {
        await getSpeakerInfo();
      })();
    }
  }, [props.state.free, props.state.selectedConference.startDate]);

  return (
    userInfo.isLoaded ?
      <>
        <form className="create-conf">
          <div className={"create-conf__conf-info"}>
            <div className="conf-info_subject">
              <p className={` ${submitFormDataErrors.subject ? "redPL" : ""}`}>(min 10, max 150 chars)</p>
              <input
                className="subject"
                placeholder="Subject"
                value={submitFormData.subject}
                onChange={e => setSubmitFormData({...submitFormData, subject: e.target.value})}
              />
            </div>
            <div className="conf-info_description">
              <p className={` ${submitFormDataErrors.description ? "redPL" : ""}`}>(min 200, max 1000 chars)</p>
              <textarea
                className="short"
                placeholder="Short description"
                value={submitFormData.description}
                maxLength={1000}
                onChange={e => setSubmitFormData({...submitFormData, description: e.target.value})}/>
            </div>
          </div>
          <div
            className={`create-conf__speakers-section ${submitFormData.speakers.length >= 5 ? "speakers-count-limited" : ""}`}>
            <div className={"speakers_title"}>
              <p>Speakers</p>
            </div>
            <div className={"empty-div"}/>
            <div className={"speakers"}>
              <Carousel
                axis={"horizontal"}
                infiniteLoop={false}
                showStatus={false}
                showIndicators={true}
                selectedItem={selectedSpeaker}
                onChange={e => setSelectedSpeaker(e)}
              >
                {submitFormData.speakers.map((speaker, index) => {
                  return (
                    <div className={`speakers__speaker-form`} key={index + 888}>
                      <div className={"speaker-form_avatar"}>
                        {speaker.avatar ?
                          <label>
                            {userInfo.isAvatarUploading && index === selectedSpeaker ?
                              <Spinner/>
                              :
                              <div className={"speaker-form_avatar_img"}
                                   style={{backgroundImage: `url(${speaker.avatar})`}}
                              />
                            }
                            <input accept="image/*" type="file" onChange={handleImageChange}/>
                          </label>
                          :
                          <label>
                            {userInfo.isAvatarUploading && index === selectedSpeaker ?
                              <Spinner/>
                              :
                              <div className={"speaker-form_avatar_img"}
                                   style={{backgroundImage: `url(${profilePicCreateIcon})`}}
                              />
                            }
                            <input accept="image/*" type="file" onChange={handleImageChange}/>
                          </label>
                        }
                      </div>
                      <div className={"speaker-form_data"}>
                        <input
                          className={submitFormDataErrors.speakers[index].name ? "redPL" : ""}
                          placeholder="Speaker name and surname *"
                          value={speaker.name}
                          required
                          onChange={e => {
                            handleChange(e.target.value, "name");
                          }}/>
                        <input
                          className={submitFormDataErrors.speakers[index].company ? "redPL" : ""}
                          placeholder="Company *"
                          required
                          value={speaker.company}
                          onChange={e => {
                            handleChange(e.target.value, "company");
                          }}/>
                        <input
                          className={submitFormDataErrors.speakers[index].title ? "redPL" : ""}
                          placeholder="Speaker title/professional degree *"
                          required
                          value={speaker.title}
                          onChange={e => {
                            handleChange(e.target.value, "title");
                          }}/>
                        <input
                          className={submitFormDataErrors.speakers[index].mail ? "redPL" : ""}
                          placeholder="Email *"
                          required
                          value={speaker.mail}
                          onChange={e => {
                            handleChange(e.target.value, "mail");
                          }}
                        />
                        <input
                          className={submitFormDataErrors.speakers[index].phone ? "redPL" : ""}
                          type={"number"}
                          placeholder="Phone Number *"
                          required
                          value={speaker.phone}
                          onChange={e => {
                            handleChange(e.target.value, "phone");
                          }}
                        />
                      </div>
                    </div>);
                })}
              </Carousel>
              {submitFormData.speakers.length > 1 ?
                <span onClick={removeSpeaker} className={"closeButton"}>
                <img src={closeIcon} alt="Close" id={"closeButton"}/>
              </span> : ""
              }
            </div>
            {submitFormData.speakers.length < 5 ?
              <div className={"add-speaker-box"}>
                <div className={"add-speaker-box_profile-icon"}>
                  <img src={profilePicCreateIcon} alt=""/>
                </div>
                <div className={"add-speaker-box_add-button"} onClick={addSpeaker}>
                  <img src={addSpeakerIcon} alt=""/>
                </div>
              </div> : ""
            }

          </div>
          <div className={"create-conf__files"}>
            <div className={"files_title"}>
              <p className={"files_title_value"}>Files </p>
              <i className={"icon-information-outline"}/>
              <div className="files_title_valid">
                <div className="add-information-inner">
                  <p>PDF,Photo,Video accepted</p>
                  <p>Max 3 files from each type.</p>
                  <p> Limitations:</p>
                  <p>+ Video 100mb</p>
                  <p>+ PDF 100mb</p>
                  <p>+ Photo 5mb</p>
                </div>
              </div>
            </div>
            <DropZone
              speakers={submitFormData.speakers}
              selectedSpeaker={selectedSpeaker}
              changeSpeakerData={changeSpeakerData}
              removedSpeakerId={removedSpeakerId}
              setRemovedSpeakerId={setRemovedSpeakerId}
              addSpeakerStatus={addSpeakerStatus}
              setAddSpeakerStatus={setAddSpeakerStatus}
              handleSubmit={handleSubmit}
              isFormValid={isFormValid}
            />
          </div>
          {userInfo.submitting ?
            <Spinner/>
            : ''
          }
        </form>
        {userInfo.submitted ?
          <div className={"submitted-popup"}>
            <div className={"submitted-popup__box"}>
              <img src={checkmarkCircle} alt="checkmarkCircle"/>
              <p className="submitted-popup__box_title">Thanks for submitting the conference/panel talk</p>
              <p className="submitted-popup__box_description">It is now sent to the {'EXPO_NAME'} moderator for
                review.<br/><br/>
                Usually the reviewing takes up to 24hrs. Corresponding
                email will be sent to your {'submitter_email_address'} email address. Please check it later to be
                updated.<br/><br/>
                Regards, {'EXPO_NAME'} administrative team
              </p>
              <button onClick={confirmSubmit}>Thank you</button>
              <div className={"close-popup"} onClick={confirmSubmit}>
                <img src={closeIcon} alt=""/>
              </div>
            </div>
          </div> : ''
        }
      </>
      :
      <Spinner />
  );
};

export default UserInfoCreate;
