import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../error-boundary';
import { Modal, ModalBody } from 'reactstrap';
import Croppie from '../../containers/croppie';

const CroppieModal = ({ data, closeModal, onSave, ...props }) => {
  return (
    <ErrorBoundary>
      <Modal size="lg" className="app-modal croppie-modal" {...props} id={"croppie-modal"}>
        <ModalBody className="bg-transparent position-relative">
          {data ? <Croppie data={data} onSave={onSave} closeModal={closeModal} /> : 'Please select file'}
        </ModalBody>
      </Modal>
    </ErrorBoundary>
  );
};

CroppieModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default CroppieModal;
