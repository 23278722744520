import axios from 'axios';
import store from '../config/redux-store';

const API_URL = process.env.REACT_APP_API_URL;

const baseInstant = axios.create({
  baseURL: API_URL,
});

baseInstant.interceptors.request.use(config => {
  const state = store.getState();

  const token = state.auth.data?.token;

  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
});

baseInstant.interceptors.response.use(
  response => {
    // Do something with response data
    const data = response.data || {};

    if (!data.success) {
      return Promise.reject({
        statusCode: response.status,
        statusText: response.statusText,
        data,
      });
    }

    return {
      statusCode: response.status,
      statusText: response.statusText,
      ...data,
    };
  },
  error => {
    // Do something with response error
    console.log(error, error.response);

    return Promise.reject({
      statusCode: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
      // ...(error.response.data || {}),
    });
  }
);

const locationInstant = axios.create({
  baseURL: API_URL,
});


locationInstant.interceptors.response.use(
  response => {
    // Do something with response data
    const data = response.data || {};

    if (data.status_code !== undefined && data.status_code !== 0) {
      return Promise.reject({
        statusCode: response.status,
        statusText: response.statusText,
        data,
      });
    }

    return {
      statusCode: response.status,
      statusText: response.statusText,
      ...data,
    };
  },
  error => {
    // Do something with response error
    console.log(error, error.response);

    return Promise.reject({
      statusCode: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
      // ...(error.response.data || {}),
    });
  }
);

class ApiService {
  constructor(_axios = axios.create()) {
    this._axios = _axios;
  }

  request = (path, { method = 'GET', data, query, headers, cancelToken } = {}) => {
    return this._axios.request({
      url: path,
      method,
      data: JSON.stringify(data),
      params: query,
      headers,
      cancelToken,
    });
  };

  postData = (path, data) => {
    return this._axios({
      'method': 'POST',
      'url': path,
      data
    })
  }

  /* USER AUTH METHODS */

  signIn = data =>
    new Promise((resolve, reject) => {
      this.request('/users/login/', { method: 'POST', data })
        .then(resolve)
        .catch(err => {
          console.log('err', err);

          reject({
            ...err,
            data: {
              message: 'Wrong username or password.',
            },
          });
        });
    });

  signOut = data => this.request('/users/logout/', { method: 'GET' });

  signUp = data => this.request('/users/', { method: 'POST', data });

  /* ROOM METHODS */

  getRoomList = query => this.request('/rooms/', { method: 'GET', query });

  getRoom = (id, query) => this.request(`/room/${id}/`, { method: 'GET', query });

  createRoom = data => this.request('/rooms/', { method: 'POST', data });

  deleteRoom = id => this.request(`/room/${id}/`, { method: 'DELETE' });

  addMembersToRoom = (id, data) => this.request(`/room/${id}/`, { method: 'PUT', data });

  createLocation = (locationId, data) => this.request(`/product/${locationId}/`, { method: 'POST', data });

  updateLocation = (data) => this.postData(`/products/`, data);

  deleteLocation = locationId => this.request(`/product/${locationId}/`, { method: 'DELETE' });

  getLocationList = query => this.request('/locations/', { method: 'GET', query });

  getDesignList = query => this.request('/products/designs/', { method: 'GET', query });

  getSearchList = (id, query) => this.request(`/products/reserved/?room_id=${id}&type=0`, { method: 'GET', query });

  getPurchaseList = (id, query) => this.request(`/products/purchase/${id}/`, { method: 'GET', query });
  /* USER METHODS */

  getUserList = data => this.request('/users/', { method: 'GET', query: data });

  getUser = id => this.request(`/users/${id}/`, { method: 'GET' });

  getSpeakerConf = id => this.request(`/conference_halls/reservations/${id}/`, { method: 'GET' });

  createUser = data => this.request('/users/', { method: 'POST', data });

  deleteUser = id => this.request(`/users/${id}`, { method: 'DELETE' });

  /* CONFERANCE METHODS */

  getConferance = id => this.request(`/conference_halls/?expo_id=${id}`, { method: 'GET' })

  reservConference = data => this.postData(`/conference_halls/reservations/`, data)

  confDuration = (id, min, date) => this.request(`/conference_halls/${id}/?duration=${min}&date=${date}&is_current=true`, { method: 'GET' })

  isAgenda = id => this.request(`/conference_halls/${id}/?is_agenda=true`, { method: 'GET' })

  isAgendaDuration = (id, min, date) => this.request(`/conference_halls/${id}/?duration=${min}&date=${date}&is_current=true&is_agenda=true`, { method: 'GET' })

  // uploadFiles = data => this.request()

  getTranslations = async (lang = 'en') => {
    const response = await fetch(`${process.env.PUBLIC_URL}/assets/locale/${lang}.json`);
    const result = await response.json();
    return result;
  };
}

const apiService = new ApiService(baseInstant);

export { ApiService };

export default apiService;
