import React, {useEffect, useRef, useState} from "react";
import cloudUploadBlack from "../../assets/images/ic_cloud_upload_black.svg"
import Spinner from "../loader";
import {ValidationHelper} from "../../helpers/ValidationHelper";
import {useDropzone} from "react-dropzone";
import {useDispatch, useSelector} from "react-redux";
import {
  addImages,
  addVideos,
  setDraggingFileID,
  setVideoThumbnails,
  updateReservingProductList
} from "../../store/actions/products";
import {getThumbnails} from 'video-metadata-thumbnails';
import {updateRoom} from "../../store/actions/room";
import {getPurchase} from "../../store/actions/purchase";


const FilesManager = (props) => {
  const dispatch = useDispatch()
  const productName = useSelector(state => state.products.selectedProductName)
  const createdFiles = useSelector(state => state.products)
  const videoThumbnails = useSelector(state => state.products.videoThumbnails)
  const filesInput = useRef(null)
  const roomId = useSelector(state => state.room.current.data.room_id)

  const [state, setState] = useState({
    submitting: false,
  })

  const isAllPurchaseUsed = () => {
    let branding = createdFiles.purchases.branding.length - createdFiles.readyForSubmit.branding.length
    let runner = createdFiles.purchases.runner.length - createdFiles.readyForSubmit.runner.length
    let logo = createdFiles.purchases.logo.length - createdFiles.readyForSubmit.logo.length
    let monitor = createdFiles.purchases.monitor.length - createdFiles.readyForSubmit.monitor.length
    return !!(branding + runner + logo + monitor)
  }

  const handleChangeFile = (e) => {
    const files = e.target.files
    if (!files[0]) return
    const validatedFiles = Array.from(files).filter(file => {
      if (productName === "monitor") {
        return ValidationHelper.VideoValidation(file)
      } else {
        return ValidationHelper.ImageValidation(file)
      }
    })

    if (productName === "monitor") {
      dispatch(addVideos(validatedFiles))
    } else {
      dispatch(addImages(validatedFiles))
    }
    filesInput.current.value = ''
  }

  const handleSave = async () => {
    const productsForReserve = createdFiles.readyForSubmit
    let reload = isAllPurchaseUsed()
    let loopAfterProduct = 'monitor'
    if (!productsForReserve.monitor.length) {
      loopAfterProduct = 'logo'
      if (!productsForReserve.logo.length) {
        loopAfterProduct = 'runner'
        if (!productsForReserve.runner.length) {
          loopAfterProduct = 'branding'
        }
      }
    }

    const notSubmittedProducts = {
      branding: [],
      runner: [],
      logo: [],
      monitor: []
    }

    Object.keys(productsForReserve).forEach(key => {
      productsForReserve[key].forEach(async (product, index) => {
        try {
          const formData = new FormData()
          formData.append('purchase_id', product.purchase_id)
          formData.append('product_schema_id', product.product_schema_id)
          formData.append('parent_id', product.parent_id)
          formData.append('parent_type', product.parent_type)

          if (key === 'monitor') {
            formData.append('product_video', product.product_video)
          } else {
            formData.append('product_photo', product.product_photo)
          }

          const response = await props.onSave(formData)
          if (response.__HANDLED_ERROR) {
            reload = true
            notSubmittedProducts[key].push(product)
          }
        } catch (e) {
          reload = true
          notSubmittedProducts[key].push(product)
        }

        if (key === loopAfterProduct && productsForReserve[key].length === index + 1) {
          if (reload) {
            dispatch(updateRoom(roomId, {extra_info: true}))
            dispatch(getPurchase(roomId))
            dispatch(updateReservingProductList(notSubmittedProducts))
          } else {
            window.location.href = 'https://www.pandamr.com/download'
          }
        }
      })
    });
  };

  const onDrop = async (e) => {
    const files = e
    if (!files[0]) return
    const validatedFiles = Array.from(files).filter(file => {
      if (productName === "monitor") {
        return ValidationHelper.VideoValidation(file)
      } else {
        return ValidationHelper.ImageValidation(file)
      }
    })
    if (productName === "monitor") {
      dispatch(addVideos(validatedFiles))
    } else {
      dispatch(addImages(validatedFiles))
    }
    filesInput.current.value = ''
  }

  const handleDown = e => {
    dispatch(setDraggingFileID(e.target.dataset.id))
  }

  const generateThumbnail = async (file) => {
    const thumbnails = await getThumbnails(file, {start: 1, end: 1});
    return thumbnails[0]
  }


  useEffect(() => {
    if (productName === "monitor") {
      (async function useEffectIIFE() {
        let thumbnails = []
        for (let file of createdFiles.videos) {
          const thumb = await generateThumbnail(file)
          thumbnails.push(thumb)
        }
        dispatch(setVideoThumbnails(thumbnails))
      })();
    }
  }, [createdFiles['videos']])

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  return (
    <>
      <div className={"advertising__form"}>
        <div className={"form__header"}>
          <h2>Work Area </h2>
          <p>Please attach branding files like Banners, Roller Banners, Logo and Video Materials.</p>
        </div>
        <div className="fields-files-wrap">
          <div className={"fields__files"}>
            <label className={"upload-box fields__files_created"} {...getRootProps()}>
              <img src={cloudUploadBlack} alt=""/>
              <p>Drag & Drop Files here
                <br/> or
              </p>
              <label className="browse-input">
                <input
                  {...getInputProps()}
                  ref={filesInput}
                  type="file"
                  accept={productName === "monitor" ? ".mp4, .mkv, .avi, .mov, .flv" : ".jpg, .jpeg, .png"}
                  onChange={e => handleChangeFile(e)}
                  multiple={true}
                />
                Browse Files
              </label>
            </label>
            {createdFiles[productName === "monitor" ? 'videos' : 'images'].map((file, index) => {
              return (
                <div className={'fields__files_created'} key={index}>
                  {productName === "monitor" ?
                    <img className="fields__files_created_img"
                         src={videoThumbnails[index] ? URL.createObjectURL(videoThumbnails[index].blob) : ''}
                         onMouseDown={e => handleDown(e)} data-id={index}
                         alt=""
                    />
                    :
                    <img className="fields__files_created_img"
                         src={URL.createObjectURL(file)}
                         onMouseDown={e => handleDown(e)} data-id={index}
                         alt=""
                    />
                  }
                </div>
              )
            })}
          </div>
        </div>
        <div className={`next-button active`}>
          <button onClick={handleSave}>
            SAVE
          </button>
        </div>
      </div>
      {state.submitting ? <Spinner/> : ''}
    </>
  )
}

export default FilesManager
