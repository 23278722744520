export const GlobalConfigData = (state, action) => {
  switch (action.type) {
    case 'UPDATE_EXPO_ID':
      return { ...state, expoId: action.expo_id };
    case 'UPDATE_CONFERENCE':
      return { ...state, conference: action.conference }
    default:
      return state;
  }
};
