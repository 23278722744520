import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';

import { routerMiddleware } from 'connected-react-router';

import { createBrowserHistory, createHashHistory } from 'history';

import { ErrorMiddleware } from '../store/middlewares';

import createRootReducer from '../store/reducers';

export const history = createHashHistory();

const isDebugEnabled = process.env.NODE_ENV !== 'production' || process.env.DEBUG || false;

function configureStore(preloadedState) {
  const composeEnhancer = isDebugEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const middleware = [
    thunk,
    ErrorMiddleware,
    createPromise({
      promiseTypeSuffixes: ['LOADING', 'SUCCESS', 'ERROR'],
    }),
    routerMiddleware(history),
  ];

  const enhancers = [applyMiddleware(...middleware)];

  const store = createStore(
    createRootReducer(history),

    preloadedState,

    composeEnhancer(...enhancers)
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../store/reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}

const store = configureStore();

export { configureStore };

export default store;
