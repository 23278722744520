import React from 'react';
import classNames from 'classnames';
import MediumMap from './medium-map'
import SmallMap from './small-map'
import { connect } from 'react-redux'
import footerImg from '../../assets/images/footer.png'
import confImg from '../../assets/images/conf-img.png'
import { NavLink, Route } from 'react-router-dom'
import { array_move } from '../../helpers/Utilities'
import { size } from 'lodash';

class BigMap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 1,
      arr: [],
      temp: 0
    }
    this.interval = null
  }
  handleSelect = location => {
    if (!location.is_reserved && location.is_active_for_user) {
      this.props.onSelect(location.public_id);
    }
  };

  componentDidMount() {
    if (this.state.arr.length <= 5 && this.state.temp !== 1) {
      this.interval = setInterval(() => {
        this.setState(state => ({
          arr: [...state.arr, 'size']
        }))
      }, 300);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  componentDidUpdate() {
    if (this.state.arr.length === 5) {
      clearInterval(this.interval)
      this.setState({
        arr: [],
        temp: 1
      })
    }
  }

  render() {
    const { index } = this.state
    const { className, selectedId } = this.props

    return (
      <div className={classNames('map-container extra-big', className)}>
        <div className='extra-big-nav'>
          {[1, 2, 3, 4].map(i =>
            <div key={i} className='dFlex_col'>
              <div onClick={() => { this.setState({ index: i }) }} className={`nav-item extra-big-nav-${i} ${this.state.arr[i - 1]}`}>
                {i === 1 ? <p>Red Hall</p> :
                  i === 2 ? <p>Blue Hall</p> :
                    i === 3 ? <p>Green Hall</p> :
                      i === 4 && <p>Orange Hall</p>}
              </div>
              {(index === 1 && i === 1) ?
                <div className='centerFlex padding0'>
                  <div className='trg-v triangleRed'/>
                </div> :
                (index === 2 && i === 2) ?
                  <div className='centerFlex padding0'>
                    <div className='trg-v triangleBlue'/>
                  </div> :
                  (index === 3 && i === 3) ?
                    <div className='centerFlex padding0'>
                      <div className='trg-v triangleGreen'/>
                    </div> :
                    (index == 4 && i === 4) &&
                      <div className='centerFlex padding0'>
                        <div className='trg-v triangleOrange'/>
                      </div> }
            </div>
          )}
        </div>
        {index !== null && <div className='bordeGrey'>
          {(index === 1 || index === 2 || index === 3) ?
            <MediumMap
              className={`${className} customBig`}
              data={this.props.data.groups[index - 1]}
              colorIndex={index}
              isOpenConf={this.props.isOpenConf}
              onSelect={this.props.onSelect}
              selectedId={selectedId}
            />
            : index === 4
            && <SmallMap
              className={`${className} customSmall`}
              data={this.props.data.groups[index - 1]}
              colorIndex={index}
              isOpenConf={this.props.isOpenConf}
              onSelect={this.props.onSelect}
              selectedId={selectedId} />}
          {index !== null && <div className='footerBig'><img src={footerImg} alt='' /></div>}
        </div>}

        {/* {index !== null && <div onClick={() => this.props.isOpenConf(true, this.props.data.groups[index-1].zones)} className='imgWrapperBig'>
          <img src={confImg} />
        </div>} */}
      </div >
    );
  }
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(BigMap);
