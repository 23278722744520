import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {getDesignList} from '../../../store/actions/room';
import ErrorBoundary from '../../../components/error-boundary';
import Spinner from '../../../components/loader';

const StandDesign = (props) => {
  const [state, setState] = useState({
    data: {
      design: null,
    },
  })

  const scrollToRef = () => window.scrollTo(0, document.body.scrollHeight - 10)

  const handleChange = value => {
    setState(state => ({
      ...state,
      data: {
        ...state.data,
        design: value,
      },
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!state.data.design) {
      return;
    }

    const {data: {locationId}} = props;

    props.onSave({designId: state.data.design, locationId});
  };

  useEffect(() => {
    props.getDesignList({
      design_label: props.data.productLabel,
    });
    return scrollToRef
  }, [])


  return (
    <ErrorBoundary>
      <div className={"submit-form__booths"}>
        <div className="booths__header">
          <h2>Work Area</h2>
          <p>Now choose best place for your stand</p>
        </div>
        <div className="booths__main">
          {!props.designs.loaded ?
            <div className="booths__main_spinner">
              <Spinner/>
            </div>
            :
            <>
              {props.designs.data.map(design => {
                  return (
                    <div key={design.public_id} onClick={() => handleChange(design.public_id)}
                         className={'booth'}
                    >
                      {state.data.design === design.public_id ?
                        <iframe
                          src={`https://standviewer.pandamr.com/simple-stand-viewer/${design.public_id}`}
                          width="100%"
                          className={'booth__preview-stand'}
                          frameBorder="0"
                          allow="autoplay; fullscreen; vr"
                          mozallowfullscreen="true"
                          webkitallowfullscreen="true"
                        />
                        :
                        <div className={`booth__preview-img`}
                             style={{backgroundImage: `url(https://standviewer.pandamr.com/models/m-${design.title}/source/m-${design.title}.png)`}}/>}
                      <div className={`booth__info ${state.data.design === design.public_id ? 'to-correct' : ''}`}>
                        <h4>{design.design_label ? design.design_label === 1 ? "M" : "B" : "S"}-{design.size}</h4>
                        <p>Size in sqm: <b>{design.size}</b>. Label: <b>{design.label}</b>. Amount of TVs: <b>4</b>.Availability
                          of logo: <b>Yes</b>. Availability of
                          website Integration: Available: Yes. Amount of presentations(in PDF Format): <b>1</b></p>
                      </div>
                    </div>
                  )
                }
              )}
            </>
          }
        </div>
        <div className={`next-button ${state.data.design ? 'active' : ''}`}>
          <button onClick={handleSubmit}>
            NEXT
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  return {
    designs: state.room.designList,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getDesignList(query) {
      return dispatch(getDesignList(query));
    },
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(StandDesign);
