import * as actionTypes from '../actions/ui';

const initialState = {
  isMobile: false,
  page: null,
  pageLoader: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UI_TOGGLE_PARAM: {
      const { param } = action.payload;
      const value = state[param];

      return {
        ...state,
        [param]: !value,
      };
    }
    case actionTypes.UI_SET_PARAM: {
      const { param, value } = action.payload;

      return {
        ...state,
        [param]: value,
      };
    }
    default:
      return state;
  }
};
