// import createAction from './create-action'
// import ApiService from '../../services/api-service';
// import cookie from 'react-cookie'
import axios from 'axios'
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_LOADED = 'UPLOAD_FILE_LOADED'
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_FILE_RESET = 'UPLOAD_FILE_RESET';

export const resetUploadingFile = () => ({ type: UPLOAD_FILE_RESET })

export const uploadFile = (files) => async (dispatch) => {
    dispatch({ type: UPLOAD_FILE_LOADED })
    try {
        // https://expo-content-dev.pandamr.com/api/media/
        const res = await axios.post(`https://expo-content-dev.pandamr.com/api/media/`, files, {
            headers: {
                'X-CSRFToken': `rUJPJ69OvpsADZsGYV7EznbxIOeIOuFG1jN5j0jZto4tMtMyeAhtRI4IB1uEQo5w`,
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                "Accept": '*/*',
                // "Accept-Encoding": "gzip, deflate, br",
                // "Connection": "keep-alive"
                // "Content-type": "multipart/form-data; boundary=----WebKitFormBoundaryLfMgGGnAcQoQZzU0",
                // "Content-Type": "multipart/form-data",
                // "Accept": "application/json, text/plain, */*"
                // "Referer": "https://expo-content-dev.pandamr.com"
            }
        });
        dispatch({
            type: UPLOAD_FILE,
            payload: res.data
        });
    } catch (err) {
        // console.log(err, 'error')
        dispatch({
            type: UPLOAD_FILE_ERROR,
            payload: { msg: err.response.data.errors.fields[0].error_message, status: err.response.status },
        });
    }
};


// export const uploadFile = files => dispatch => {
//     fetch(`https://expo-content-dev.pandamr.com/api/media/`, {
//         method: 'POST',
//         headers: {
//             'X-CSRFToken': localStorage.getItem('CSRF'),
//             'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
//             "Accept": '*/*',
//         },
//         body: files
//     }).then(response => response.json())
//         .then(res => {
//             dispatch({
//                 type: UPLOAD_FILE,
//                 payload: res.data
//             })
//         })
//         .catch(err => {
//             dispatch({
//                 type: UPLOAD_FILE_ERROR,
//                 payload: { msg: err.response.statusText, status: err.response.status }
//             })
//         })
// }