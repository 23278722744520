import React, {useEffect, useRef, useState} from "react";
import Calendar from "react-calendar";
import Spinner from "../../../components/loader/spinner";
import UserInfo from "./userInfo/UserInfo";
import {DateHelper} from "../../../helpers/DateHelper";
import ConferenceManager from "../../../managers/Conference/ConferenceManager";
import conferenceIcon from "../../../assets/images/conferenceIcon.svg";
import conferenceIconActive from "../../../assets/images/conferenceIconActive.svg";
import conferenceIconBlue from "../../../assets/images/conferenceIconBlue.svg";
import panelTalkIcon from "../../../assets/images/panelTalkIcon.svg";
import panelTalkIconActive from "../../../assets/images/panelTalkIconActive.svg";
import panelTalkIconBlue from "../../../assets/images/panelTalkIconBlue.svg";
import arrowBlue from "../../../assets/images/arrow-blue.png";
import {UseConferencesDataState} from "../../../context/ConferancesDataContext";
import UserInfoCreate from "./userInfo/UserInfoCreate";
import ConfEmptyPage from "./ConfEmptyPage";

const Conf = (props) => {

  const timeZoneRanges = [
    {time: "00:00", dayZone: "am"},
    {time: "00:30", dayZone: "am"},
    {time: "01:00", dayZone: "am"},
    {time: "01:30", dayZone: "am"},
    {time: "02:00", dayZone: "am"},
    {time: "02:30", dayZone: "am"},
    {time: "03:00", dayZone: "am"},
    {time: "03:30", dayZone: "am"},
    {time: "04:00", dayZone: "am"},
    {time: "04:30", dayZone: "am"},
    {time: "05:00", dayZone: "am"},
    {time: "05:30", dayZone: "am"},
    {time: "06:00", dayZone: "am"},
    {time: "06:30", dayZone: "am"},
    {time: "07:00", dayZone: "am"},
    {time: "07:30", dayZone: "am"},
    {time: "08:00", dayZone: "am"},
    {time: "08:30", dayZone: "am"},
    {time: "09:00", dayZone: "am"},
    {time: "09:30", dayZone: "am"},
    {time: "10:00", dayZone: "am"},
    {time: "10:30", dayZone: "am"},
    {time: "11:00", dayZone: "am"},
    {time: "11:30", dayZone: "am"},
    {time: "12:00", dayZone: "pm"},
    {time: "12:30", dayZone: "pm"},
    {time: "13:00", dayZone: "pm"},
    {time: "13:30", dayZone: "pm"},
    {time: "14:00", dayZone: "pm"},
    {time: "14:30", dayZone: "pm"},
    {time: "15:00", dayZone: "pm"},
    {time: "15:30", dayZone: "pm"},
    {time: "16:00", dayZone: "pm"},
    {time: "16:30", dayZone: "pm"},
    {time: "17:00", dayZone: "pm"},
    {time: "17:30", dayZone: "pm"},
    {time: "18:00", dayZone: "pm"},
    {time: "18:30", dayZone: "pm"},
    {time: "19:00", dayZone: "pm"},
    {time: "19:30", dayZone: "pm"},
    {time: "20:00", dayZone: "pm"},
    {time: "20:30", dayZone: "pm"},
    {time: "21:00", dayZone: "pm"},
    {time: "21:30", dayZone: "pm"},
    {time: "22:00", dayZone: "pm"},
    {time: "22:30", dayZone: "pm"},
    {time: "23:00", dayZone: "pm"},
    {time: "23:30", dayZone: "pm"},
    {time: "24:00", dayZone: "pm"}
  ];
  const durations = ["30", "60", "90", "120"];
  const ref = useRef(null);
  const {conferencesDataState} = UseConferencesDataState();

  const [state, setState] = useState({
    free: false,
    user: {},
    calendarSelectedDate: new Date() < new Date(props.startDate) ? new Date(props.startDate) : new Date(),
    isLoaded: false,
    selectedConference: {
      publicId: ""
    },
    createConference: {
      isOpened: false,
      startDate: "",
      endDate: "",
      duration: "",
      durationOpened: false,
      availableDurations: 4,
      isConfirmed: false
    }
  });

  const [conferenceEvents, setConferenceEvents] = useState([]);

  const handleChangeCalendar = date => {
    setState({
      ...state,
      calendarSelectedDate: date,
      index: null,
      free: null,
      isLoaded: false,
      selectedConference: {
        publicId: ""
      },
      createConference: {
        isOpened: false,
        startDate: "",
        endDate: "",
        duration: "",
        durationOpened: false,
        availableDurations: 4,
        isConfirmed: false
      }
    });
  };

  const durationDropDown = () => {
    setState({
      ...state,
      createConference: {...state.createConference, durationOpened: !state.createConference.durationOpened}
    });
  };

  const selectDuration = (duration) => {
    const startTimeZoneIndex = timeZoneRanges.findIndex(tomeZone => {
      return tomeZone.time === state.createConference.startDate;
    });

    const durationIndex = duration / 30;

    const endDate = timeZoneRanges[startTimeZoneIndex + durationIndex];

    setState({
      ...state,
      createConference:
        {
          ...state.createConference,
          duration: duration,
          durationOpened: false,
          endDate: endDate
        }
    });
  };

  const getConferenceData = async () => {
    try {
      const response = await ConferenceManager.getConferenceByDay(props.id, DateHelper.dateFormatYYYYMMDD(state.calendarSelectedDate));
      setState({
        ...state,
        isLoaded: true
      });
      setConferenceEvents(response.data.data.events);
    } catch (e) {

    }
  };

  const handleLineClick = (timeZone, index) => {
    let durationNumber = 4

    switch (index) {
      case 47:
        durationNumber = 1
        break;
      case 46:
        conferenceEvents.find(event => {
          switch (event.start_date) {
            case timeZoneRanges[index + 1].time :
              return durationNumber = 1;
            default:
              return durationNumber = 2;
          }
        });
        break;
      case 45:
        conferenceEvents.find(event => {
          switch (event.start_date) {
            case timeZoneRanges[index + 2].time :
              return durationNumber = 2;
            case timeZoneRanges[index + 1].time :
              return durationNumber = 1;
            default:
              return durationNumber = 3;
          }
        });
        break;
      default:
        conferenceEvents.find(event => {
          switch (event.start_date) {
            case timeZoneRanges[index + 3].time :
              return durationNumber = 3;
            case timeZoneRanges[index + 2].time :
              return durationNumber = 2;
            case timeZoneRanges[index + 1].time :
              return durationNumber = 1;
            default:
              break;
          }
        });
        break
    }

    const endDate = timeZoneRanges[index + 1].time;

    let options = {
      isOpened: true,
      startDate: timeZone.time,
      endDate: endDate,
      duration: "30",
      durationOpened: false,
      availableDurations: durationNumber
    };

    setState({
      ...state,
      createConference: options,
      free: true,
      selectedConference: {
        ...state.selectedConference,
        publicId: ""
      }
    });
  };

  const confirmDuration = () => {
    setState({
      ...state,
      createConference: {
        ...state.createConference,
        isOpened: false,
        durationOpened: false,
        isConfirmed: true
      }
    });
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setState({...state, createConference: {...state.createConference, isOpened: false}});
    }
  };

  const openReservedConferenceData = (event) => {
    setState({
      ...state,
      free: false,
      selectedConference: {
        ...state.selectedConference,
        publicId: event.public_id
      },
      createConference: {
        isOpened: false,
        startDate: "",
        endDate: "",
        duration: "",
        durationOpened: false,
        availableDurations: 4,
        isConfirmed: false
      }
    });
  };

  const handleTimeZone = () => {

    return timeZoneRanges.map((timeZone, timeZoneIndex) => {
        let reservedBoxStyle = {};
        let reservingBoxStyle = {};
        const event = conferenceEvents.find(event => {
          if (event.start_date === timeZone.time) {
            let timeDif =
              (new Date().setHours(+event.end_date.split(":")[0], +event.end_date.split(":")[1])
                -
                new Date().setHours(+event.start_date.split(":")[0], +event.start_date.split(":")[1]))
              / (1000 * 60 * 30);
            reservedBoxStyle.height = 32 * timeDif;
            return event;
          }
        });

        if (state.createConference.isOpened || state.createConference.isConfirmed && timeZone.time === state.createConference.startDate) {
          reservingBoxStyle.height = state.createConference.duration / 30 * 32;
        }

        return (
          <div className={"time-zone__slot"} key={timeZone.time}>
            <span className={"time-zone__slot_time"}>{timeZone.time}</span>
            <span className={"time-zone__slot_line"}
                  onClick={() => {
                    handleLineClick(timeZone, timeZoneIndex);
                  }}
            />
            {event ?
              <div
                className={`time-zone__slot_reserved ${state.selectedConference.publicId === event.public_id ? "selected" : ""}`}
                style={reservedBoxStyle}>
                <div className={"slot_reserved-box"} onClick={() => openReservedConferenceData(event)}>
                  <div className={"slot_reserved-box_subject"}>
                    {event.subject}
                  </div>
                  <div className={"slot_reserved-box_icon"}>
                    <span>{event.type}</span>
                    <img className={"disabled"} src={event.type === "Panel Talk" ? panelTalkIcon : conferenceIcon}
                         alt=""/>
                    <img className={"hover"} src={event.type === "Panel Talk" ? panelTalkIconBlue : conferenceIconBlue}
                         alt=""/>
                    <img className={"active"}
                         src={event.type === "Panel Talk" ? panelTalkIconActive : conferenceIconActive} alt=""/>
                  </div>
                </div>
              </div> : ""
            }
            {state.createConference.isConfirmed && timeZone.time === state.createConference.startDate ?
              <div className={"time-zone__slot_reserving"} style={reservingBoxStyle}>
                <div className={"slot_reserved-box slot_reserving-box"}>
                  <div className={"slot_reserved-box_subject slot_reserving-box_subject"}>
                    {conferencesDataState.reservingConferenceData.subject}
                  </div>
                  <div className={"slot_reserved-box_icon slot_reserving-box_icon"}>
                    <span>{conferencesDataState.reservingConferenceData.type}</span>
                    <img className={"active"}
                         src={conferencesDataState.reservingConferenceData.type === "Panel Talk" ? panelTalkIconActive : conferenceIconActive}
                         alt=""/>
                  </div>
                </div>
              </div> : ""
            }
            {state.createConference.isOpened && timeZone.time === state.createConference.startDate ?
              <div className={"time-zone__slot__options-popup"} ref={ref} style={reservingBoxStyle}>
                <div className={"options-popup_start"}>
                  <span className={"options-popup_start-name"}>Start</span>
                  <span className={"options-popup_start-value"}>{timeZone.time} {timeZone.dayZone}</span>
                </div>
                <div className={"options-popup_duration"}>
                  <span className={"options-popup_duration-name"}>Durations</span>
                  <span className={"options-popup_duration-value"}>
                    <span onClick={durationDropDown}
                          className={`selected-duration-value ${state.createConference.durationOpened ? "opened" : ""}`}>
                      {state.createConference.duration}
                      min.
                      <img src={arrowBlue} alt={"arrowBlue"}/>
                    </span>
                    {state.createConference.durationOpened ? Array.from(Array(state.createConference.availableDurations)).map((n, durationsIndex) => {
                      return (
                        <span className={"selected-duration-item"}
                              onClick={() => selectDuration(durations[durationsIndex])}>{durations[durationsIndex]} min.</span>
                      );
                    }) : ""}
                  </span>
                </div>
                <div className={"options-popup_confirm"} onClick={confirmDuration}>
                  Confirm
                </div>
              </div> : ""
            }
          </div>
        );
      }
    );
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    (async function useEffectIIFE() {
      if (state.calendarSelectedDate) {
        await getConferenceData();
      }
    })();
  }, [state.calendarSelectedDate]);

  const minDate = new Date(props.startDate);
  const maxDate = new Date(props.endDate);

  return (
    <div className={`conf-grid-holder`}>
      <div className={`conf__calendar ${!state.calendarSelectedDate ? "selected" : ""}`}>
        <Calendar
          onChange={date => handleChangeCalendar(date)}
          value={state.calendarSelectedDate}
          minDate={minDate}
          maxDate={maxDate}
          tileClassName={date => {
            const thisDay = new Date(date.date);
            if (thisDay >= DateHelper.getDateWhitStartTime(minDate) && thisDay <= maxDate) {
              let classNames = "activeForExpo";
              if (thisDay.getDay() === 1) {
                classNames += " weekStart";
              } else if (thisDay.getDay() === 0) {
                classNames += " weekEnd";
              }
              if (thisDay.getTime() === DateHelper.getDateWhitStartTime(maxDate)) {
                classNames += " expoEndDay";
              } else if (thisDay.getTime() === DateHelper.getDateWhitStartTime(minDate)) {
                classNames += " expoStartDay";
              }
              return classNames;
            } else return null;
          }}
        />
      </div>
      <div
        className={`conf__user-info ${state.createConference.isConfirmed || state.selectedConference.publicId ? "selected" : ""}`}>
        {state.isLoaded ?
          state.free ?
            state.createConference.isConfirmed ?
              <UserInfoCreate
                state={state}
                hallId={props.id}
                handleModalState={props.handleModalState}
              />
              :
              <ConfEmptyPage text={"Choose a time slot"}/>
            :
            state.selectedConference.publicId ?
              <UserInfo
                selectedConference={state.selectedConference}
              />
              :
              <ConfEmptyPage text={"Choose a time slot"}/>
          :
          <Spinner/>
        }
      </div>
      <div className={`conf__time-zone ${state.createConference.isOpened ? "disabled" : ""}
           ${state.calendarSelectedDate && !state.createConference.isConfirmed && !state.selectedConference.publicId ? "selected" : ""}`}
      >
        <div className="scroll-contain">
          {state.isLoaded ?
            <div className="conf__time-zone__range">
              {handleTimeZone()}
              <span className={"conf__time-zone__range_vertical-line"}/>
              <div className={`conf__time-zone_disabled`}/>
            </div>
            :
            <Spinner/>}
        </div>
      </div>
    </div>
  );
};

export default Conf;
