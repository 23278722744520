import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  addImages,
  addProductForSubmit,
  addVideos,
  openFilesPopup,
  setImageToCroppie,
  updateProduct
} from "../../store/actions/products";
import cloudUploadBlack from "../../assets/images/ic_cloud_upload_black.svg";
import {useDropzone} from "react-dropzone";
import {ValidationHelper} from "../../helpers/ValidationHelper";

const FilesPopup = () => {
  const filesInput = useRef(null)

  const dispatch = useDispatch()
  const reservingProducts = useSelector(state => state.products)
  const productName = useSelector(state => state.products.selectedProductName)
  const halls = useSelector(state => state.room.current.data.halls)
  const videoThumbnails = useSelector(state => state.products.videoThumbnails)
  const roomId = useSelector(state => state.room.current.data.room_id)


  const selectMedia = (e) => {
    e.preventDefault()
    e.stopPropagation()

    let media = reservingProducts[reservingProducts.selectedProductName === 'monitor' ? 'videos' : "images"][e.target.dataset.index]

    if (reservingProducts.selectedProductName === 'monitor') {

      const product = halls[reservingProducts.hallIndex].zones[reservingProducts.zoneIndex][reservingProducts.selectedProductName][reservingProducts.selectedProductId - 1]
      const isRes = reservingProducts.readyForSubmit[reservingProducts.selectedProductName].find(findingProduct => {
        return findingProduct.product_schema_id === product.public_id
      })

      if (isRes) {
        dispatch(updateProduct(media, product.public_id))
        return
      }
      let purchaseIndex = reservingProducts.readyForSubmit[reservingProducts.selectedProductName].length
      const purchaseId = reservingProducts.purchases[reservingProducts.selectedProductName][purchaseIndex].public_id

      const data = {}
      data.product_schema_id = product.public_id
      data.parent_id = roomId
      data.parent_type = 1
      data.purchase_id = purchaseId

        data.product_video = media
      console.log(data);
      dispatch(addProductForSubmit(data))
    } else {
      dispatch(setImageToCroppie(media, reservingProducts.selectedProductId))
    }
  }


  const handleChangeFile = (e) => {
    const files = e.target.files
    if (!files[0]) return
    const validatedFiles = Array.from(files).filter(file => {
      if (productName === "monitor") {
        return ValidationHelper.VideoValidation(file)
      } else {
        return ValidationHelper.ImageValidation(file)
      }
    })

    if (productName === "monitor") {
      dispatch(addVideos(validatedFiles))
    } else {
      dispatch(addImages(validatedFiles))
    }
    filesInput.current.value = ''
  }

  const onDrop = async (e) => {
    const files = e
    if (!files[0]) return
    const validatedFiles = Array.from(files).filter(file => {
      if (productName === "monitor") {
        return ValidationHelper.VideoValidation(file)
      } else {
        return ValidationHelper.ImageValidation(file)
      }
    })
    if (productName === "monitor") {
      dispatch(addVideos(validatedFiles))
    } else {
      dispatch(addImages(validatedFiles))
    }
    filesInput.current.value = ''
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  return (
    <div className="popup-wrap-inner">
      <span className={'close'} onClick={() => dispatch(openFilesPopup(false, 0))}/>
      <div className="work-area-wrap">
        <div className="work-area-top">
          <h1>Work Area</h1>
          <p>Please crop your image to the exact sieze (940x270)px</p>
        </div>
        <div className="work-area-middle">
          <div className="work-area-middle-inner">
            <label className={"upload-box fields__files_created"} {...getRootProps()}>
              <img src={cloudUploadBlack} alt=""/>
              <p>Drag & Drop Files here
                <br/> or
              </p>
              <label className="browse-input">
                <input
                  {...getInputProps()}
                  ref={filesInput}
                  type="file"
                  accept={productName === "monitor" ? ".mp4, .mkv, .avi, .mov, .flv" : ".jpg, .jpeg, .png"}
                  onChange={e => handleChangeFile(e)}
                  multiple={true}
                />
                Browse Files
              </label>
            </label>
            {reservingProducts[productName === "monitor" ? 'videos' : 'images'].map((file, index) => {
              return (
                <div className={'work-area-middle-i-row'} key={index}>
                  {productName === "monitor" ?
                    <img className="fields__files_created_img"
                         src={videoThumbnails[index] ? URL.createObjectURL(videoThumbnails[index].blob) : ''}
                         onClick={selectMedia} data-index={index}
                         alt=""
                    />
                    :
                    <img className="fields__files_created_img"
                         src={URL.createObjectURL(file)}
                         onClick={selectMedia} data-index={index}
                         alt=""
                    />
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>

  )
}

export default FilesPopup
