import React, {Component} from 'react';
import closeIcon from '../../assets/images/close.png'
import {connect} from 'react-redux';
import Spinner from '../../components/loader';
import {getSearchList} from '../../store/actions/search';
import PandaLogo from "../../assets/images/panda_logo.svg";
import searchIconLight from "../../assets/images/search.svg";
import {StandCustomMap} from "../../components/locations-map";

class StandView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      opConf: false,
      searchVal: '',
      searchResult: null,
      showSearch: null,
      formData: {
        locationId: null,
      },
    };
    this.counter = 0
  }

  isOpenConf = bool => {
    this.setState({ opConf: bool })
  }

  componentDidMount() {
    this.props.makeSearch(this.props.auth.data.expo_id).then(payload => {
      this.setState({ searchResult: payload.value.data })
    })
  }

  handleChangeSearch = e => {
    this.setState({ searchVal: e.target.value })
    let arr = this.state.searchResult
    const length = e.target.value.length
    let arr1 = arr.filter(item => item.reserved_by.office.substr(0, length).toLowerCase() === e.target.value.toLowerCase())
    this.setState({ showSearch: arr1 })
  }

  render() {
    const { formData, showSearch } = this.state;
    const { room } = this.props;

    return (
      this.props.isLoaded ?
        <div className={"floor-plan"}>
          <div className={"floor-plan__header"}>
            <img src={PandaLogo} alt=""/>
          </div>
          <div className={"floor-plan__title"}>
            <h2>{room.data.room.name} Floor Plan</h2>
            <p>Now choose the best place for your stand</p>
          </div>
          <div className={"floor-plan__main"}>
            <div className={"floor-plan__main_search"}>
              <img src={searchIconLight} alt=""/>
              <input
                onChange={e => this.handleChangeSearch(e)}
                value={this.state.searchVal}
                placeholder='Search...'
              />
              {this.state.searchVal !== '' &&
              <div className="close-icon" onClick={() => this.setState({...this.state, searchVal: '', showSearch: null})}>
                <img src={closeIcon} alt=''/>
              </div>}
              {this.state.searchVal &&
              <div className={"search__result"}>
                {showSearch !== null ?
                  <>
                    {showSearch.length > 0 && <p className={"search__result_title"}>Exhibitors</p>}
                    {showSearch.length === 0 ?
                      <p className={"search__result_no-result"}>No Results</p> :
                      <div className={"search__result_items"}>
                        {showSearch.map(item =>
                          <div key={item.public_id} className={"search__result_item"}>
                            <p className={"search__result_item_name"}>{item.reserved_by.office}</p>
                            <span/>
                            <div className={"search__result_item_info"}>
                              <h5>{item.group === 1 ? 'R-' : item.group === 2 ? 'B-' : item.group === 3 ? 'G-' : 'O-'}{item.index}</h5>
                              <p>Zone-{item.zone}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    }
                  </>
                  :
                  <Spinner/>
                }
              </div>}
            </div>
            <div className={"floor-plan__main_halls"}>
              <StandCustomMap
                data={room.data}
                selectedId={formData.locationId}
              />
            </div>
          </div>
        </div>
        :
        <Spinner/>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoaded: state.search.current.loaded,
    search: state.search.current.data,
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    makeSearch: (id, query) => dispatch(getSearchList(id, query))
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(StandView)
