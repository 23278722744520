import React, { Component } from 'react';

import ErrorIndicator from '../error-indicator/error-indicator';
// import * as Sentry from '@sentry/browser';

// console.log('process.env.NODE_ENV, ', process.env.NODE_ENV);

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({ dsn: 'https://769b5a266fa0456ab434d254d5722a45@sentry.io/1885581' });
// }

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('-----------');
      console.error(error);
      console.error(errorInfo.componentStack);
    } else {
      // Sentry.withScope(scope => {
      //   scope.setExtras(errorInfo);
      //   Sentry.captureException(error);
      // });
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorIndicator />;
    }

    return this.props.children;
  }
}
