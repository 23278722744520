export const ConferencesData = (state, action) => {
  switch (action.type) {
    case "AVATAR_UPLOAD_ERROR":
      state.errors.speakerAvatar = action.error;
      return state;
    case "FILES_UPLOAD_ERROR":
      state.errors.speakerFiles = action.error;
      return state;
    case "CHANGE_CONFERENCE_DATA":
      state.reservingConferenceData = action.data;
      return state;
    default:
      return state;
  }
};
