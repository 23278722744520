import React from "react";
import axios from "axios";

export default {

  async getCSRFToken() {
    const config = {
      headers: { Authorization: `Token ${localStorage.getItem("token")}` }
    };
    return await axios.get(process.env.REACT_APP_FILE_UPLOADE_URL + "generate-csrf/", config);
  },

  async uploadFile(formData, setProgress) {
    const config = {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: e => {
        setProgress((e.loaded * 100) / e.total);
      },
    };
    return await axios.post(process.env.REACT_APP_FILE_UPLOADE_URL + "media/", formData, config);
  },

};
