function ImageValidation(file) {
  let re = /(\.jpg|\.jpeg|\.png)$/i;
  return file.size * 0.00000095367432 <= 5 && re.exec(file.name);
}

function PDFValidation(file) {
  let re = /(\.pdf|)$/i;
  return file.size * 0.00000095367432 <= 100 && re.exec(file.name);
}

function VideoValidation(file) {
  let re = /(\.mp4|\.mkv|\.avi|\.mov|\.flv)$/i;
  return file.size * 0.00000095367432 <= 100 && re.exec(file.name);
}

function WebSiteValidation(webSite) {
  let re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  return !!re.test(webSite)
}

function MobilePhoneValidation (PhoneNumber) {
  let re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
  return !!re.test(PhoneNumber)
}

export const ValidationHelper = {
  ImageValidation,
  PDFValidation,
  VideoValidation,
  WebSiteValidation,
  MobilePhoneValidation
}
